/* --------------------------------------
=========================================
Adminkit - Multipurpose bootstrap 4.0 admin templates
Version: 1.0
Designed By: UXLiner
=========================================
******************/

/*Vertical tabs*/
.vtabs {
	display: table;
}
.vtabs .tabs-vertical {
	width: 150px;
	border-bottom: 0px;
	border-right: 1px solid rgba(120, 130, 140, 0.13);
	display: table-cell;
	vertical-align: top;
}
.vtabs .tabs-vertical li .nav-link {
	color: #263238;
	margin-bottom: 10px;
	border: 0px;
	border-radius: 4px 0 0 4px;
}
.vtabs .tab-content {
	display: table-cell;
	padding: 20px;
	vertical-align: top;
}
.tabs-vertical li .nav-link.active, .tabs-vertical li .nav-link:hover, .tabs-vertical li .nav-link.active:focus {
	background: #0077d3;
	border: 0px;
	color: #ffffff;
}
/*Custom vertical tab*/
.customtab li a.nav-link{
	border:0;
}
.customvtab .tabs-vertical li .nav-link.active, .customvtab .tabs-vertical li .nav-link:hover, .customvtab .tabs-vertical li .nav-link:focus {
	background: #ffffff;
	border: 0px;
	border-right: 2px solid #0077d3;
	margin-right: -1px;
	color: #0077d3;
}
.tabcontent-border {
	border: 1px solid #ddd;
	border-top: 0px;
}
.customtab2 li a.nav-link {
	border: 0px;
	margin-right: 3px;
	color: #67757c;
}
.customtab2 li a.nav-link.active {
	background: #0077d3;
	color: #ffffff;
}
.customtab2 li a.nav-link:hover {
	color: #ffffff;
	background: #0077d3;
}
