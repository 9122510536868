/* --------------------------------------
=========================================
Adminkit - Multipurpose bootstrap 4.0 admin templates
Version: 1.0
Designed By: UXLiner
=========================================
1. Layout
2. Header
	+ logo
	+ searchbar
	+ user panel
3. sidebar
	+ nav
	+ tree nav
4. Content
	+ charts
	+ reports
	+ map
	+ mails
	+ products
	+ social media
	+ processbars
	+ calendar
	+ to do list
	+ orders
5. Footer
	+ copyrights
--------------------------------------------------------*/

/* Layout */
html, body {
	height: 100%
}
.layout-boxed html, .layout-boxed body {
	height: 100%
}
body {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	overflow-x: hidden;
	overflow-y: auto;
	font-size: 15px;
	color: #666f73;
}
.boxed-wrapper {
	background-color: #f0f4f8;
}
.wrapper {
	height: 100%;
	position: relative;/*overflow-x: hidden;
	overflow-y: auto*/
}
.wrapper:before, .wrapper:after {
	content: " ";
	display: table
}
.wrapper:after {
	clear: both
}
.layout-boxed .wrapper {
	max-width: 1250px;
	margin: 0 auto;
	min-height: 100%;
	box-shadow: 0 0 8px rgba(0,0,0,0.5);
	position: relative
}
.layout-boxed {
	background: url('./img/boxed-bg.jpg') repeat fixed;
}
.content-wrapper, .main-footer {
	-webkit-transition: -webkit-transform .3s ease-in-out, margin .3s ease-in-out;
	-moz-transition: -moz-transform .3s ease-in-out, margin .3s ease-in-out;
	-o-transition: -o-transform .3s ease-in-out, margin .3s ease-in-out;
	transition: transform .3s ease-in-out, margin .3s ease-in-out;
	margin-left: 230px;
	z-index: 820
}
.main-footer-full {
	margin-left: 0px;
	z-index: 820;
}
.content-wrapper-full {
	min-height: 100%;
}
.layout-top-nav .content-wrapper, .layout-top-nav .main-footer {
	margin-left: 0
}
a {
	transition: all 0.3s ease-out 0s;
	text-decoration: none;
	outline: 0;
}
a:hover, a:active, a:focus {
	outline: none;
	text-decoration: none;
	transition: all 0.3s ease-out 0s;
}
@media (max-width:767px) {
.content-wrapper, .main-footer {
	margin-left: 0
}
}
@media (min-width:768px) {
.sidebar-collapse .content-wrapper, .sidebar-collapse .main-footer {
	margin-left: 0
}
}
@media (max-width:767px) {
.sidebar-open .content-wrapper, .sidebar-open .main-footer {
	-webkit-transform: translate(230px, 0);
	-ms-transform: translate(230px, 0);
	-o-transform: translate(230px, 0);
	transform: translate(230px, 0)
}
}
.content-wrapper {
	min-height: 100%;
	background-color: #f0f4f8;
	z-index: 800
}
.content-wrapper-full {
	min-height: 100%;
	background-color: #f0f4f8;
	z-index: 800
}
.main-footer, .main-footer-full {
	background: #fff;
	padding: 15px;
	color: #444;
	font-size: 13px;
	border-top: 1px solid #d2d6de
}
.user-panel > div > p {
	color: #192844;
}
.fixed .main-header, .fixed .main-sidebar, .fixed .left-side {
	position: fixed
}
.fixed .main-header {
	top: 0;
	right: 0;
	left: 0
}
.fixed .content-wrapper, .fixed .right-side {
	padding-top: 50px
}
@media (max-width:767px) {
.fixed .content-wrapper, .fixed .right-side {
	padding-top: 100px
}
}
.fixed.layout-boxed .wrapper {
	max-width: 100%
}
.fixed .wrapper {
	overflow: hidden
}
.hold-transition .content-wrapper, .hold-transition .right-side, .hold-transition .main-footer, .hold-transition .main-sidebar, .hold-transition .left-side, .hold-transition .main-header .navbar, .hold-transition .main-header .logo, .hold-transition .menu-open .fa-angle-left {
	-webkit-transition: none;
	-o-transition: none;
	transition: none
}
.content {
	/*min-height: 250px;*/
	padding: 10px;
	margin-right: auto;
	margin-left: auto;
}
h1, h2, h3, h4, h5, h6 {
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
}
h2 {
	font-size: 30px;
}
h3 {
	font-size: 25px;
}
h4 {
	font-size: 22px;
}
h5 {
	font-size: 18px;
}
h6 {
	font-size: 16px;
	margin-bottom: 10px;
}
a {
	color: #0077d3
}
a:hover, a:active, a:focus {
	outline: none;
	text-decoration: none;
	color: #7b88f3
}
.page-header {
	margin: 10px 0 20px 0;
	font-size: 22px
}
.no-wrap td, .no-wrap th {
	white-space: nowrap
}
b {
	font-weight: bolder;
	color: #595959;
}
.page-header>small {
	color: #666;
	display: block;
	margin-top: 5px
}
.main-header {
	position: relative;
	max-height: 120px;
	/*z-index: 1030;*/
}
.main-header .navbar {
	-webkit-transition: margin-left .3s ease-in-out;
	-o-transition: margin-left .3s ease-in-out;
	transition: margin-left .3s ease-in-out;
	margin-bottom: 0;
	margin-left: 230px;
	border: none;
	min-height: 60px;
	border-radius: 0;
	display: inherit;
}
.main-header .navbar.blue-bg {
	background: #192844;
	border-bottom: solid 0px #ebebeb;
}
.main-header .navbar.white-bg {
	background: #fff;
	border-bottom: solid 0px #ebebeb;
	box-shadow: 10px 10px 20px -5px rgba(0, 0, 0, 0.08);
}
.layout-top-nav .main-header .navbar {
	margin-left: 0
}
.main-header #navbar-search-input.form-control {
	background: rgba(255,255,255,0.2);
	border-color: transparent
}
.main-header #navbar-search-input.form-control:focus, .main-header #navbar-search-input.form-control:active {
	border-color: rgba(0,0,0,0.1);
	background: rgba(255,255,255,0.9)
}
.main-header #navbar-search-input.form-control::-moz-placeholder {
color:#ccc;
opacity:1
}
.main-header #navbar-search-input.form-control:-ms-input-placeholder {
color:#ccc
}
.main-header #navbar-search-input.form-control::-webkit-input-placeholder {
color:#ccc
}
.main-header .navbar-custom-menu, .main-header .navbar-right {
	float: right
}
.main-header .navbar {
	padding: 0;
}
.main-header .navbar .navbar-nav {
	float: left;
	display: inherit;
}
.main-header .navbar .navbar-nav > li {
	float: left;
	display: block;
}
.main-header .navbar .navbar-nav > li > a {
	padding: 23px 20px 22px 20px;
	display: block;
	line-height: 14px;
}
.main-header .navbar .navbar-nav > li > a i {
	font-size: 14px;
}
.main-header .navbar .navbar-nav > li > a.dropdown-toggle::after {
	border-left: 0;
	border-right: 0;
	border-top: 0;
 content: normal;
	margin: 0;
	display: inherit;
}
.img-circle {
	border-radius: 50%;
}
.icon-circle {
	border-radius: 100%;
	height: 36px;
	width: 36px;
	line-height: 38px;
	text-align: center;
	color: #FFF;
}
.icon-circle.red {
	background: #ef5350;
}
.icon-circle.green {
	background: #00a65a;
}
.icon-circle.blue {
	background: #0077d3;
}
.icon-circle.yellow {
	background: #f39c12;
}
.notify {
	position: relative;
	right: -8px;
	top: -10px;
}
.notify .heartbit {
	animation: 1s ease-out 0s normal none infinite running heartbit;
	border: 5px solid #ff5d19;
	border-radius: 70px;
	height: 25px;
	position: absolute;
	right: -4px;
	top: -20px;
	width: 25px;
	z-index: 10;
}
.notify .point {
	background-color: #ff5d19;
	border-radius: 30px;
	height: 6px;
	position: absolute;
	right: 6px;
	top: -10px;
	width: 6px;
}
@keyframes heartbit {
0% {
 opacity: 0;
 transform: scale(0);
}
25% {
 opacity: 0.1;
 transform: scale(0.1);
}
50% {
 opacity: 0.3;
 transform: scale(0.5);
}
75% {
 opacity: 0.5;
 transform: scale(0.8);
}
100% {
 opacity: 0;
 transform: scale(1);
}
}
@keyframes heartbit {
0% {
 opacity: 0;
 transform: scale(0);
}
25% {
 opacity: 0.1;
 transform: scale(0.1);
}
50% {
 opacity: 0.3;
 transform: scale(0.5);
}
75% {
 opacity: 0.5;
 transform: scale(0.8);
}
100% {
 opacity: 0;
 transform: scale(1);
}
}
@media (max-width:991px) {
.main-header .navbar-custom-menu a, .main-header .navbar-right a {
	color: inherit;
	background: transparent
}
}
@media (max-width:767px) {
.main-header .navbar-right {
	float: none
}
.navbar-collapse .main-header .navbar-right {
	margin: 7.5px -15px
}
.main-header .navbar-right>li {
	color: inherit;
	border: 0
}
}
.main-header .blue-bg .navbar-nav > li > a {
	color: #fff;
}
.main-header .white-bg .navbar-nav > li > a {
	color: #192844;
}
.main-header .navbar-nav > li > a.sidebar-toggle {
	float: left;
	background-color: transparent;
	background-image: none;
	padding: 23px 20px !important;
	font-family: fontAwesome;
	font-size: 14px;
}
.main-header .sidebar-toggle:before {
	content: "\f0c9"
}
.main-header .sidebar-toggle:hover {
	color: #fff
}
.main-header .sidebar-toggle:focus, .main-header .sidebar-toggle:active {
	background: transparent
}
.main-header .sidebar-toggle .icon-bar {
	display: none
}
.main-header .navbar .nav>li.user>a>.fa, .main-header .navbar .nav>li.user>a>.glyphicon, .main-header .navbar .nav>li.user>a>.ion {
	margin-right: 5px
}
.main-header .navbar .nav>li>a>.label {
	position: absolute;
	top: 9px;
	right: 7px;
	text-align: center;
	font-size: 9px;
	padding: 2px 3px;
	line-height: .9
}
.main-header .logo {
	-webkit-transition: width .3s ease-in-out;
	-o-transition: width .3s ease-in-out;
	transition: width .3s ease-in-out;
	display: block;
	float: left;
	height: 60px;
	font-size: 20px;
	line-height: 55px;
	text-align: center;
	width: 230px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	padding: 0 15px;
	font-weight: 300;
	overflow: hidden
}
.main-header {
	background-color: #fff;
}
.main-header .logo .logo-lg {
	display: block;
}
.main-header .logo .logo-mini {
	display: none
}
.main-header .logo.blue-bg {
	background: #192844;
	border-bottom: solid 1px #3255c7;
}
.main-header .logo.white-bg {
	background: #fff;
	border-bottom: solid 1px #3255c7;
}
.main-header .navbar-brand {
	color: #fff
}
.main-header .search-box {
	width: 30%;
	margin-left: 1%;
}
.main-header .search-form {
	margin-top: 11px;
}
.main-header .search-form input.form-control {
	border: 0;
	font-size: 12px;
}
.main-header .search-form input.form-control {
	background: #fff;
	border-bottom: 0px solid #e5e7e9;
	color: #999999;
	font-family: 'Poppins', sans-serif;
	padding: 0;
}
.main-header .search-form input.form-control::-moz-placeholder {
 color: #999999;
}
.main-header .search-form button.btn {
	border-radius: 0 3px 3px 0;
	border: 0;
}
.main-header .search-form button.btn {
	background: #fff;
	border-bottom: 0px solid #e5e7e9;
	color: #999999;
	cursor: pointer;
}
.content-header {
	position: relative;
	padding: 15px 15px 0 15px
}
.content-header.sty-one {
	position: relative;
	padding: 20px 15px 15px 15px;
	box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
	background: #4164d8;
	margin: 0 0 0px;
}
.content-header>h1 {
	margin: 0;
	font-size: 18px;
	color:#FFF;
}
.content-header>h1>small {
	font-size: 15px;
	display: inline-block;
	padding-left: 4px;
	font-weight: 300
}
.content-header>.breadcrumb {
	float: right;
	background: transparent;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 12px;
	padding: 7px 5px;
	position: absolute;
	top: 15px;
	right: 10px;
	border-radius: 2px
}
.content-header>.breadcrumb>li {
	display: inline-block;
	color: #8fa5ec;
}
.content-header>.breadcrumb>li>a {
	color: #fff;
	text-decoration: none;
	display: inline-block;
	padding-right: 5px;
}
.content-header>.breadcrumb>li>a>.fa, .content-header>.breadcrumb>li>a>.glyphicon, .content-header>.breadcrumb>li>a>.ion {
	margin-right: 5px
}
.content-header>.breadcrumb>li.sub-bread {
	color: #696969;
}
.bc-colored .breadcrumb-item, .bc-colored .breadcrumb-item a {
	color: #fff;
}
.bc-colored .breadcrumb-item.active, .bc-colored .breadcrumb-item a.active {
	color: #fff;
	opacity: 0.6;
}
.bc-colored .breadcrumb-item + .breadcrumb-item::before {
	color: #fff;
}
ul.list-icons {
	margin: 0px;
	padding: 0px;
}
ul.list-icons li {
	list-style: none;
	line-height: 30px;
	margin: 5px 0;
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
}
ul.list-icons li a {
	color: #666f73;
}
.fontawesome-icon-list div, .icon-section div, .weater-icon-list div, .simple-icon-list div, .flag-icon-list div {
	cursor: pointer;
	line-height: 40px;
	white-space: nowrap;
	color: #67757c;
}
.fontawesome-icon-list i, .icon-section span, .weater-icon-list i, .simple-icon-list i, .flag-icon-list i {
	font-size: 16px;
	margin: 0 15px 0 10px;
}
ul.fa-ul {
	margin-bottom: 0;
}
.tab-style1 {
	border: 0;
}
.tab-style1 ul.nav-tabs {
	border-bottom: 1px solid #ddd;
	height: 42px;
}
.tab-style1 ul li {
	margin-right: 1px;
}
.tab-style1 ul li a, .customtab li a {
	background-color: transparent;
	color: #8d8d8d;
	padding: 10px 18px;
}
.tab-style1 ul li a:hover, .customtab li a.nav-link:hover {
	background-color: transparent;
	color: #0077d3;
}
.tab-style1 ul.nav-tabs .nav-item.show .nav-link, .tab-style1 ul.nav-tabs .nav-link.active, .tab-style1 ul.nav-tabs .nav-link {
	border: 0;
}
.tab-style1 ul.nav-tabs li a.active, .customtab li a.nav-link.active {
	background-color: transparent;
	color: #0077d3;
	border-bottom: solid 1px #0077d3 !important;
}
.bd-example-row .row > .col, .bd-example-row .row > [class^="col-"] {
 padding-top: .75rem;
 padding-bottom: .75rem;
 background-color: rgba(86,61,124,.15);
 border: 1px solid rgba(86,61,124,.2);
}
.img-rounded {
	border-radius: 5px;
}
.like-comm {
	padding: 15px 0 10px 0;
	border-bottom: solid 1px #e5e5e5;
}
.like-comm a {
	color: #7b7b7b;
	padding-right: 10px;
}
.like-comm a:hover {
	color: #0077d3;
}
.soci-wid-box {
	padding: 30px 10px 20px;
	color: #FFF;
	border-radius: 5px;
}
.soci-wid-box .sco-icon {
	font-size: 30px;
	margin-bottom: 10px;
}
.bg-twitter {
	background: #28aae1;
}
.bg-facebook {
	background: #3b5998;
}
.bg-google-plus {
	background: #dc4a38;
}
.sl-item {
	border-left: 1px solid #13b1e0;
	padding-bottom: 1px;
	padding-left: 15px;
	position: relative;
}
.sl-item::before {
	background-color: #13b1e0;
	border-radius: 100%;
	content: "";
	height: 12px;
	left: -6.5px;
	position: absolute;
	top: 0;
	width: 12px;
}
.sl-item:last-child::after {
	border-radius: 100%;
	bottom: 0;
	content: "";
	height: 6px;
	left: -3px;
	position: absolute;
	width: 6px;
}
.sl-item .sl-content i {
	font-size: 12px;
}
.sl-item .sl-content small {
	position: relative;
	top: -4px;
}
.sl-item .sl-content p {
	padding-bottom: 4px;
	position: relative;
}
.sl-primary {
	border-left-color: #2196f3;
}
.sl-primary::before, .sl-primary:last-child::after {
	background-color: #2196f3;
}
.sl-danger {
	border-left-color: #f44336;
}
.sl-danger::before, .sl-danger:last-child::after {
	background-color: #f44336;
}
.sl-success {
	border-left-color: #4caf50;
}
.sl-success::before, .sl-success:last-child::after {
	background-color: #4caf50;
}
.sl-warning {
	border-left-color: #ff5722;
}
.sl-warning::before, .sl-warning:last-child::after {
	background-color: #ff5722;
}
.tile-progress {
	background-color: #303641;
	color: #fff;
}
.tile-progress {
	background: #00a65b;
	color: #fff;
	margin-bottom: 20px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	background-clip: padding-box;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
.tile-progress .tile-header {
	padding: 15px 20px;
}
.tile-progress .tile-progressbar {
	height: 2px;
	background: rgba(0,0,0,0.18);
	margin: 0;
}
.tile-progress .tile-progressbar span {
	background: #fff;
}
.tile-progress .tile-progressbar span {
	display: block;
	background: #fff;
	width: 0;
	height: 100%;
	-webkit-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
	-moz-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
	-o-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
	transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
}
.tile-progress .tile-footer {
	padding: 20px;
	text-align: right;
	background: rgba(0,0,0,0.1);
	-webkit-border-radius: 0 0 3px 3px;
	-webkit-background-clip: padding-box;
	-moz-border-radius: 0 0 3px 3px;
	-moz-background-clip: padding;
	border-radius: 0 0 3px 3px;
	background-clip: padding-box;
	-webkit-border-radius: 0 0 3px 3px;
	-moz-border-radius: 0 0 3px 3px;
	border-radius: 0 0 3px 3px;
}
.tile-progress.tile-red {
	background-color: #f56954;
	color: #fff;
}
.tile-progress {
	background-color: #303641;
	color: #fff;
}
.tile-progress.tile-blue {
	background-color: #0073b7;
	color: #fff;
}
.tile-progress.tile-aqua {
	background-color: #00c0ef;
	color: #fff;
}
.tile-progress.tile-green {
	background-color: #00a65a;
	color: #fff;
}
.tile-progress.tile-cyan {
	background-color: #00b29e;
	color: #fff;
}
.tile-progress.tile-purple {
	background-color: #ba79cb;
	color: #fff;
}
.tile-progress.tile-pink {
	background-color: #ec3b83;
	color: #fff;
}
.box-gradient {
	background: #328dff;
	background: -webkit-linear-gradient(135deg, #3d74f1, #9986ee);
	background: linear-gradient(-45deg, #3d74f1, #9986ee);
}
.box-gradient-1 {
	background: #b756ff;
	background: -webkit-linear-gradient(135deg, #b756ff, #c576ff);
	background: linear-gradient(-45deg, #b756ff, #c576ff);
}
.box-gradient-2 {
	background: #7a86ff;
	background: -webkit-linear-gradient(135deg, #7a86ff, #949dff);
	background: linear-gradient(-45deg, #7a86ff, #949dff);
}
.box-gradient-3 {
	background: #fab63f;
	background: -webkit-linear-gradient(135deg, #fab63f, #fbc465);
	background: linear-gradient(-45deg, #fab63f, #fbc465);
}
.box-gradient-4 {
	background: #fe413b;
	background: -webkit-linear-gradient(135deg, #fe413b, #fc7572);
	background: linear-gradient(-45deg, #fe413b, #fc7572);
}
 @media (max-width:991px) {
.content-header>.breadcrumb {
	position: relative;
	margin-top: 5px;
	top: 0;
	right: 0;
	float: none;
	background: #000;
	padding-left: 10px
}
.content-header>.breadcrumb li:before {
	color: #97a0b3
}
}
.navbar-toggle {
	color: #fff;
	border: 0;
	margin: 0;
	padding: 15px 15px
}
@media (max-width:991px) {
.navbar-custom-menu .navbar-nav>li {
	float: left
}
.navbar-custom-menu .navbar-nav {
	margin: 0;
	float: left
}
.navbar-custom-menu .navbar-nav>li>a {
	padding-top: 15px;
	padding-bottom: 15px;
	line-height: 20px
}
}
@media (max-width:767px) {
.main-header {
	position: relative
}
.main-header .logo, .main-header .navbar {
	width: 100%;
	float: none
}
.main-header .navbar {
	margin: 0
}
.main-header .navbar-custom-menu {
	float: right
}
}
@media (max-width:991px) {
.navbar-collapse.pull-left {
	float: none !important
}
.navbar-collapse.pull-left+.navbar-custom-menu {
	display: block;
	position: absolute;
	top: 0;
	right: 40px
}
}
.main-sidebar {
	position: absolute;
	top: 0;
	left: 0;
	padding-top: 60px;
	min-height: 100%;
	width: 230px;
	background: #fff;
	/*z-index: 810;*/
	box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
	-webkit-transition: -webkit-transform .3s ease-in-out, width .3s ease-in-out;
	-moz-transition: -moz-transform .3s ease-in-out, width .3s ease-in-out;
	-o-transition: -o-transform .3s ease-in-out, width .3s ease-in-out;
	transition: transform .3s ease-in-out, width .3s ease-in-out
}
@media (max-width:767px) {
.main-sidebar {
	padding-top: 60px
}
}
@media (max-width:767px) {
.main-sidebar {
	-webkit-transform: translate(-230px, 0);
	-ms-transform: translate(-230px, 0);
	-o-transform: translate(-230px, 0);
	transform: translate(-230px, 0)
}
}
@media (min-width:768px) {
.sidebar-collapse .main-sidebar {
	-webkit-transform: translate(-230px, 0);
	-ms-transform: translate(-230px, 0);
	-o-transform: translate(-230px, 0);
	transform: translate(-230px, 0)
}
}
@media (max-width:767px) {
.sidebar-open .main-sidebar {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0)
}
}
.sidebar {
	padding-bottom: 10px
}
.sidebar-form input:focus {
	border-color: transparent
}
.user-panel {
	position: relative;
	width: 100%;
	padding: 10px;
	overflow: hidden;
	border-bottom: solid 0px #ebebeb;
	background-color: #fff;
}
.user-panel:before, .user-panel:after {
	content: " ";
	display: table
}
.user-panel:after {
	clear: both
}
.user-panel>.image {
	padding: 10px 0;
}
.user-panel>.image>img {
	width: 100%;
	max-width: 45px;
	height: auto
}
.user-panel>.info {
	padding: 5px 0px;
	line-height: 1;
	text-align: center;
	color: #FFF;
	font-size: 14px;
}
.user-panel>.info>p {
	margin-bottom: 6px;
}
.user-panel>.info>a {
	text-decoration: none;
	font-size: 10px
}
.user-panel>.info>a>.fa, .user-panel>.info>a>.ion, .user-panel>.info>a>.glyphicon {
	margin-right: 3px
}
.sidebar-menu {
	list-style: none;
	margin: 0;
	padding: 0
}
.sidebar-menu>li {
	position: relative;
	margin: 0;
	padding: 0
}
.sidebar-menu>li>a {
	padding: 12px 5px 12px 15px;
	display: block;
	font-size: 14px;
	color: #192844;
}
.sidebar-menu > li.active > a {
	border-left: solid 3px #FF5D19;
}
.sidebar-menu > li.active > a {
	background-color: #FF5D19 !important;
	color: #fff;
}
.sidebar-menu > li.menu-open > a {
	background-color: #FF5D19 !important;
	color: #fff;
}
.sidebar-menu>li>a>.fa, .sidebar-menu>li>a>.glyphicon, .sidebar-menu>li>a>.ion {
	width: 20px
}
.sidebar-menu>li>a>.fas, .sidebar-menu>li>a>.glyphicon, .sidebar-menu>li>a>.ion {
	width: 20px
}
.sidebar-menu>li .label, .sidebar-menu>li .badge {
	margin-right: 5px
}
.sidebar-menu>li .badge {
	margin-top: 3px
}
.sidebar-menu li.header {
	padding: 10px 25px 10px 15px;
	font-size: 12px;
	/*border-bottom: solid 1px #404b59;
	border-top: solid 1px #404b59;*/
	color: #fff;
	font-weight: 500;
}
.sidebar-menu li>a>.fa-angle-left, .sidebar-menu li>a>.pull-right-container>.fa-angle-left {
	width: auto;
	height: auto;
	padding: 0;
	margin-right: 10px;
	-webkit-transition: transform .5s ease;
	-o-transition: transform .5s ease;
	transition: transform .5s ease
}
.sidebar-menu li>a>.fa-angle-left {
	position: absolute;
	top: 50%;
	right: 10px;
	margin-top: -8px
}
.sidebar-menu .menu-open>a>.fa-angle-left, .sidebar-menu .menu-open>a>.pull-right-container>.fa-angle-left {
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	transform: rotate(-90deg)
}
.sidebar-menu .active>.treeview-menu {
	display: block;
}
ul.list-inline li {
	display: inline-block;
	padding: 0 8px;
	font-size: 14px;
}
.progress.bg-lightblue {
	background: rgb(0, 0, 0, 0.2);
	border-radius: 10px;
}
.bg-white {
	background-color: #FFF;
	border-radius: 10px;
}
 @media (min-width:768px) {
.sidebar-mini.sidebar-collapse .content-wrapper, .sidebar-mini.sidebar-collapse .right-side, .sidebar-mini.sidebar-collapse .main-footer {
	margin-left: 50px !important;
	z-index: 840
}
.sidebar-mini.sidebar-collapse .main-sidebar {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	width: 50px !important;
	z-index: 850
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li {
	position: relative
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li>a {
	margin-right: 0;
	/*background-color: #fff;*/
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li>a>span {
/*border-top-right-radius: 4px*/
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li:not(.treeview)>a>span {
/*border-bottom-right-radius: 4px*/
}
.sidebar-mini.sidebar-collapse .sidebar-menu>li>.treeview-menu {
	padding-top: 5px;
	padding-bottom: 5px;
	background: #efefef;
}
.sidebar-mini.sidebar-collapse .main-sidebar .user-panel>.info, .sidebar-mini.sidebar-collapse .sidebar-form, .sidebar-mini.sidebar-collapse .sidebar-menu>li>a>span, .sidebar-mini.sidebar-collapse .sidebar-menu>li>.treeview-menu, .sidebar-mini.sidebar-collapse .sidebar-menu>li>a>.pull-right, .sidebar-mini.sidebar-collapse .sidebar-menu li.header {
	display: none !important;
	-webkit-transform: translateZ(0)
}
.sidebar-mini.sidebar-collapse .main-header .logo {
	width: 50px
}
.sidebar-mini.sidebar-collapse .main-header .logo>.logo-mini {
	display: block;
	margin-left: -15px;
	margin-right: -15px;
	font-size: 18px
}
.sidebar-mini.sidebar-collapse .main-header .logo>.logo-lg {
	display: none
}
.sidebar-mini.sidebar-collapse .main-header .navbar {
	margin-left: 50px
}
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li:hover>a>span:not(.pull-right), .sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li:hover>.treeview-menu {
	display: block !important;
	position: absolute;
	width: 200px;
	left: 50px
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li.menu-open a span {
	top: 0;
	margin-left: -3px !important;
	padding: 12px 5px 12px 20px;
	background-color: inherit
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li:hover>a>span {
	top: 0;
	margin-left: 0px;
	padding: 12px 5px 12px 20px;
	background-color: #FF5D19;
	color:#FFF;
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li:hover>a>.pull-right-container {
	position: relative !important;
	float: right;
	width: auto !important;
	left: 180px !important;
	top: -22px !important;
	z-index: 900
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li:hover>a>.pull-right-container>.label:not(:first-of-type) {
	display: none
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li:hover>.treeview-menu {
	top: 45px;
	margin-left: 0
}
.sidebar-expanded-on-hover .main-footer, .sidebar-expanded-on-hover .content-wrapper {
	margin-left: 50px
}
.sidebar-expanded-on-hover .main-sidebar {
	box-shadow: 3px 0 8px rgba(0,0,0,0.125)
}
.sidebar-menu, .main-sidebar .user-panel, .sidebar-menu>li.header {
	white-space: nowrap;
	overflow: hidden
}
.sidebar-menu:hover {
	overflow: visible
}
.sidebar-form, .sidebar-menu>li.header {
	overflow: hidden;
	text-overflow: clip
}
.sidebar-menu li>a {
	position: relative
}
.sidebar-menu li>a>.pull-right-container {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -7px
}
.control-sidebar-bg {
	position: fixed;
	z-index: 1000;
	bottom: 0
}
.control-sidebar-bg, .control-sidebar {
	top: 0;
	right: -230px;
	width: 230px;
	-webkit-transition: right .3s ease-in-out;
	-o-transition: right .3s ease-in-out;
	transition: right .3s ease-in-out
}
.control-sidebar {
	position: absolute;
	padding-top: 50px;
	z-index: 1010
}
@media (max-width:768px) {
.control-sidebar {
	padding-top: 100px
}
}
.control-sidebar>.tab-content {
	padding: 10px 15px
}
.control-sidebar.control-sidebar-open, .control-sidebar.control-sidebar-open+.control-sidebar-bg {
	right: 0
}
.control-sidebar-open .control-sidebar-bg, .control-sidebar-open .control-sidebar {
	right: 0
}
@media (min-width:768px) {
.control-sidebar-open .content-wrapper, .control-sidebar-open .right-side, .control-sidebar-open .main-footer {
	margin-right: 230px
}
}
.fixed .control-sidebar {
	position: fixed;
	height: 100%;
	overflow-y: auto;
	padding-bottom: 50px
}
.nav-tabs.control-sidebar-tabs>li:first-of-type>a, .nav-tabs.control-sidebar-tabs>li:first-of-type>a:hover, .nav-tabs.control-sidebar-tabs>li:first-of-type>a:focus {
	border-left-width: 0
}
.nav-tabs.control-sidebar-tabs>li>a {
	border-radius: 0
}
.nav-tabs.control-sidebar-tabs>li>a, .nav-tabs.control-sidebar-tabs>li>a:hover {
	border-top: none;
	border-right: none;
	border-left: 1px solid transparent;
	border-bottom: 1px solid transparent
}
.nav-tabs.control-sidebar-tabs>li>a .icon {
	font-size: 16px
}
.nav-tabs.control-sidebar-tabs>li.active>a, .nav-tabs.control-sidebar-tabs>li.active>a:hover, .nav-tabs.control-sidebar-tabs>li.active>a:focus, .nav-tabs.control-sidebar-tabs>li.active>a:active {
	border-top: none;
	border-right: none;
	border-bottom: none
}
@media (max-width:768px) {
.nav-tabs.control-sidebar-tabs {
	display: table
}
.nav-tabs.control-sidebar-tabs>li {
	display: table-cell
}
}
.control-sidebar-heading {
	font-weight: 400;
	font-size: 16px;
	padding: 10px 0;
	margin-bottom: 10px
}
.control-sidebar-subheading {
	display: block;
	font-weight: 400;
	font-size: 14px
}
.control-sidebar-menu {
	list-style: none;
	padding: 0;
	margin: 0 -15px
}
.control-sidebar-menu>li>a {
	display: block;
	padding: 10px 15px
}
.control-sidebar-menu>li>a:before, .control-sidebar-menu>li>a:after {
	content: " ";
	display: table
}
.control-sidebar-menu>li>a:after {
	clear: both
}
.control-sidebar-menu>li>a>.control-sidebar-subheading {
	margin-top: 0
}
.control-sidebar-menu .menu-icon {
	float: left;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	text-align: center;
	line-height: 35px
}
.control-sidebar-menu .menu-info {
	margin-left: 45px;
	margin-top: 3px
}
.control-sidebar-menu .menu-info>.control-sidebar-subheading {
	margin: 0
}
.control-sidebar-menu .menu-info>p {
	margin: 0;
	font-size: 11px
}
.control-sidebar-menu .progress {
	margin: 0
}
.control-sidebar-dark {
	color: #b8c7ce
}
.control-sidebar-dark, .control-sidebar-dark+.control-sidebar-bg {
	background: #222d32
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs {
	border-bottom: #1c2529
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a {
	background: #181f23;
	color: #b8c7ce
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a, .control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a:hover, .control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a:focus {
	border-left-color: #141a1d;
	border-bottom-color: #141a1d
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a:hover, .control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a:focus, .control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a:active {
	background: #1c2529
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs>li>a:hover {
	color: #fff
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs>li.active>a, .control-sidebar-dark .nav-tabs.control-sidebar-tabs>li.active>a:hover, .control-sidebar-dark .nav-tabs.control-sidebar-tabs>li.active>a:focus, .control-sidebar-dark .nav-tabs.control-sidebar-tabs>li.active>a:active {
	background: #222d32;
	color: #fff
}
.control-sidebar-dark .control-sidebar-heading, .control-sidebar-dark .control-sidebar-subheading {
	color: #fff
}
.control-sidebar-dark .control-sidebar-menu>li>a:hover {
	background: #1e282c
}
.control-sidebar-dark .control-sidebar-menu>li>a .menu-info>p {
	color: #b8c7ce
}
.control-sidebar-light {
	color: #5e5e5e
}
.control-sidebar-light, .control-sidebar-light+.control-sidebar-bg {
	background: #f9fafc;
	border-left: 1px solid #d2d6de
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs {
	border-bottom: #d2d6de
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs>li>a {
	background: #e8ecf4;
	color: #444
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs>li>a, .control-sidebar-light .nav-tabs.control-sidebar-tabs>li>a:hover, .control-sidebar-light .nav-tabs.control-sidebar-tabs>li>a:focus {
	border-left-color: #d2d6de;
	border-bottom-color: #d2d6de
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs>li>a:hover, .control-sidebar-light .nav-tabs.control-sidebar-tabs>li>a:focus, .control-sidebar-light .nav-tabs.control-sidebar-tabs>li>a:active {
	background: #eff1f7
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs>li.active>a, .control-sidebar-light .nav-tabs.control-sidebar-tabs>li.active>a:hover, .control-sidebar-light .nav-tabs.control-sidebar-tabs>li.active>a:focus, .control-sidebar-light .nav-tabs.control-sidebar-tabs>li.active>a:active {
	background: #f9fafc;
	color: #111
}
.control-sidebar-light .control-sidebar-heading, .control-sidebar-light .control-sidebar-subheading {
	color: #111
}
.control-sidebar-light .control-sidebar-menu {
	margin-left: -14px
}
.control-sidebar-light .control-sidebar-menu>li>a:hover {
	background: #f4f4f5
}
.control-sidebar-light .control-sidebar-menu>li>a .menu-info>p {
	color: #5e5e5e
}
.dropdown-menu {
	box-shadow: none;
	border-color: #eee
}
.dropdown-menu>li {
	width: 100%;
}
.dropdown-menu>li>a {
	color: #777;
	padding: 6px 20px;
	display: block;
	font-size: 14px;
}
.dropdown-menu>li>a>.glyphicon, .dropdown-menu>li>a>.fa, .dropdown-menu>li>a>.ion {
	margin-right: 10px
}
.dropdown-menu>li>a:hover {
	background-color: #e1e3e9;
	color: #333
}
.dropdown-menu>.divider {
	background-color: #bbb;
	height: 1px
}
.navbar-nav>.notifications-menu>.dropdown-menu, .navbar-nav>.messages-menu>.dropdown-menu, .navbar-nav>.tasks-menu>.dropdown-menu {
	width: 300px;
	padding: 0 0 0 0;
	margin: 1px 0 0 0;
	top: 100%
}
.navbar-nav>.notifications-menu>.dropdown-menu>li, .navbar-nav>.messages-menu>.dropdown-menu>li, .navbar-nav>.tasks-menu>.dropdown-menu>li {
	position: relative
}
.navbar-nav>.notifications-menu>.dropdown-menu>li.header, .navbar-nav>.messages-menu>.dropdown-menu>li.header, .navbar-nav>.tasks-menu>.dropdown-menu>li.header {
	background-color: #ffffff;
	padding: 10px;
	border-bottom: 1px solid #f4f4f4;
	color: #2c2c2c;
	font-size: 15px
}
.navbar-nav>.notifications-menu>.dropdown-menu>li.footer, .navbar-nav>.messages-menu>.dropdown-menu>li.footer, .navbar-nav>.tasks-menu>.dropdown-menu>li.footer {
	text-align: center !important;
	background-color: #fff;
	padding: 10px;
	border-bottom: 1px solid #eeeeee;
}
.navbar-nav>.notifications-menu>.dropdown-menu>li.footer>a, .navbar-nav>.messages-menu>.dropdown-menu>li.footer>a, .navbar-nav>.tasks-menu>.dropdown-menu>li.footer>a {
	background-color: transparent;
	font-size: 15px;
	color: #444 !important;
}
.navbar-nav>.notifications-menu>.dropdown-menu>li.footer>a:hover, .navbar-nav>.messages-menu>.dropdown-menu>li.footer>a:hover, .navbar-nav>.tasks-menu>.dropdown-menu>li.footer>a:hover {
	color: #0077d3 !important;
}
@media (max-width:991px) {
.navbar-nav>.notifications-menu>.dropdown-menu>li.footer>a, .navbar-nav>.messages-menu>.dropdown-menu>li.footer>a, .navbar-nav>.tasks-menu>.dropdown-menu>li.footer>a {
	background: #fff !important;
	color: #444 !important
}
}
.navbar-nav>.notifications-menu>.dropdown-menu>li.footer>a:hover, .navbar-nav>.messages-menu>.dropdown-menu>li.footer>a:hover, .navbar-nav>.tasks-menu>.dropdown-menu>li.footer>a:hover {
	text-decoration: none;
	font-weight: normal
}
.navbar-nav>.notifications-menu>.dropdown-menu>li .menu, .navbar-nav>.messages-menu>.dropdown-menu>li .menu, .navbar-nav>.tasks-menu>.dropdown-menu>li .menu {
	margin: 0;
	padding: 0;
	list-style: none;
	overflow-x: hidden
}
.navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>a, .navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a, .navbar-nav>.tasks-menu>.dropdown-menu>li .menu>li>a {
	display: block;
	white-space: nowrap;
	border-bottom: 1px solid #f4f4f4
}
.navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>a:hover, .navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a:hover, .navbar-nav>.tasks-menu>.dropdown-menu>li .menu>li>a:hover {
	background: #e7eaee;
	text-decoration: none
}
.navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>a {
	color: #444444;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 10px
}
.navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>a>.glyphicon, .navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>a>.fa, .navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>a>.ion {
	width: 20px
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a {
	margin: 0;
	padding: 10px 10px
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>div>img {
	margin: auto 10px auto auto;
	width: 40px;
	height: 40px
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>div> .profile-status {
	border: 2px solid #ffffff;
	border-radius: 50%;
	display: inline-block;
	height: 10px;
	left: 40px;
	position: absolute;
	width: 10px;
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>div> .online {
	background: #26dad2 none repeat scroll 0 0;
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>div> .busy {
	background: #ef5350 none repeat scroll 0 0;
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>div> .away {
	background: #ffb22b none repeat scroll 0 0;
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>div> .offline {
	background: #ffb22b none repeat scroll 0 0;
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>h4 {
	padding: 0;
	margin: 0 0 3px 45px;
	color: #262626;
	font-size: 16px;
	position: relative
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>h4>small {
	color: #828282;
	font-size: 12px;
	position: absolute;
	top: 0;
	right: 0
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>span {
	color: #67757c;
	font-size: 12px;
	margin: 0 0 0 0px;
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>p {
	margin: 0 0 0 45px;
	font-size: 12px;
	color: #888888
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a:before, .navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a:after {
	content: " ";
	display: table
}
.navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a:after {
	clear: both
}
.navbar-nav>.tasks-menu>.dropdown-menu>li .menu>li>a {
	padding: 10px
}
.navbar-nav>.tasks-menu>.dropdown-menu>li .menu>li>a>h3 {
	font-size: 14px;
	padding: 0;
	margin: 0 0 10px 0;
	color: #666666
}
.navbar-nav>.tasks-menu>.dropdown-menu>li .menu>li>a>.progress {
	padding: 0;
	margin: 0
}
.navbar-nav > .user-menu > .dropdown-menu {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-top-width: 0;
	padding: 1px 0 0;
	width: 280px;
}
.navbar-nav > .user-menu > .dropdown-menu, .navbar-nav > .user-menu > .dropdown-menu > .user-body {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
	/*height: 95px;*/
	padding: 10px;
	text-align: center;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > .user-img {
	margin-right: 10px;
	width: 70px;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > p {
	color: #000;
	font-size: 17px;
	margin-top: 10px;
	z-index: 5;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > p > small {
	display: block;
	font-size: 12px;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > .view-link {
	margin-top: 5px;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > .view-link a {
	background: #f34167 none repeat scroll 0 0;
	border-radius: 30px;
	color: #fff;
	font-size: 12px;
	padding: 3px 10px;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header > .view-link a:hover {
	background: #ff6f8e none repeat scroll 0 0;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body {
	border-bottom: 1px solid #eeeeee;
	border-top: 1px solid #dddddd;
	padding: 15px;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body::before, .navbar-nav > .user-menu > .dropdown-menu > .user-body::after {
	content: " ";
	display: table;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body::after {
	clear: both;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body a {
	color: #444;
}
.img-responsive {
	display: block;
	height: auto;
	max-width: 100%;
}
 @media (max-width:991px) {
.navbar-nav>.user-menu>.dropdown-menu>.user-body a {
	background: #fff !important;
	color: #444 !important
}
}
.navbar-nav>.user-menu>.dropdown-menu>.user-footer {
	background-color: #f9f9f9;
	padding: 10px
}
.navbar-nav>.user-menu>.dropdown-menu>.user-footer:before, .navbar-nav>.user-menu>.dropdown-menu>.user-footer:after {
	content: " ";
	display: table
}
.navbar-nav>.user-menu>.dropdown-menu>.user-footer:after {
	clear: both
}
.navbar-nav>.user-menu>.dropdown-menu>.user-footer .btn-default {
	color: #666666
}
@media (max-width:991px) {
.navbar-nav>.user-menu>.dropdown-menu>.user-footer .btn-default:hover {
	background-color: #f9f9f9
}
}
.navbar-nav>.user-menu .user-image {
	float: left;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	margin-right: 10px;
	margin-top: -2px
}
@media (max-width:767px) {
.navbar-nav>.user-menu .user-image {
	float: none;
	margin-right: 0;
	margin-top: -8px;
	line-height: 10px
}
}
.open:not(.dropup)>.animated-dropdown-menu {
	backface-visibility: visible !important;
	-webkit-animation: flipInX .7s both;
	-o-animation: flipInX .7s both;
	animation: flipInX .7s both
}
@keyframes flipInX {
0% {
transform:perspective(400px) rotate3d(1, 0, 0, 90deg);
transition-timing-function:ease-in;
opacity:0
}
40% {
transform:perspective(400px) rotate3d(1, 0, 0, -20deg);
transition-timing-function:ease-in
}
60% {
transform:perspective(400px) rotate3d(1, 0, 0, 10deg);
opacity:1
}
80% {
transform:perspective(400px) rotate3d(1, 0, 0, -5deg)
}
100% {
transform:perspective(400px)
}
}
@-webkit-keyframes flipInX {
0% {
-webkit-transform:perspective(400px) rotate3d(1, 0, 0, 90deg);
-webkit-transition-timing-function:ease-in;
opacity:0
}
40% {
-webkit-transform:perspective(400px) rotate3d(1, 0, 0, -20deg);
-webkit-transition-timing-function:ease-in
}
60% {
-webkit-transform:perspective(400px) rotate3d(1, 0, 0, 10deg);
opacity:1
}
80% {
-webkit-transform:perspective(400px) rotate3d(1, 0, 0, -5deg)
}
100% {
-webkit-transform:perspective(400px)
}
}
.navbar-custom-menu>.navbar-nav>li {
	position: relative
}
.navbar-custom-menu>.navbar-nav>li>.dropdown-menu {
	position: absolute;
	right: 0;
	left: auto
}
@media (max-width:991px) {
	.navbar-custom-menu>.navbar-nav {
		float: right
	}
	.navbar-custom-menu>.navbar-nav>li {
		position: static
	}
	.navbar-custom-menu>.navbar-nav>li>.dropdown-menu {
		position: absolute;
		right: 5%;
		left: auto;
		border: 1px solid #ddd;
		background: #fff
	}
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition-duration: 0.15s, 0.15s;
}
.form-control:focus {
	border-color: #3c8dbc;
	box-shadow: none
}
.form-control::-moz-placeholder, .form-control:-ms-input-placeholder, .form-control::-webkit-input-placeholder {
color:#bbb;
opacity:1
}
.form-control.sty1 {
	border-radius: 0px;
	box-shadow: none;
	border: 0;
	border-bottom: solid 1px #d9d9d9;
	font-size: 16px;
	font-family: 'Poppins', sans-serif;
	padding: 10px 0;
}
.form-control.sty1:focus {
	border-color: #0077d3;
	box-shadow: none
}
input[type="file"] {
	display: block;
}
.custom-file-control {
	-moz-user-select: none;
	background-color: #fff;
	border: 1px solid #e4e7ed;
	border-radius: 0.25rem;
	left: 0;
	right: 0;
	top: 0;
	z-index: 5;
}
.custom-file-control:lang(en)::after {
	content: "Choose file...";
}
.custom-file-control:lang(en)::before {
	content: "Browse";
}
.custom-file-control::before {
	background-color: #eee;
	border: 1px solid #e4e7ed;
	border-radius: 0 0.25rem 0.25rem 0;
	bottom: -1px;
	display: block;
	right: -1px;
	top: -1px;
	z-index: 6;
}
.form-control-feedback {
	position: absolute;
	margin-top: -34px;
	right: 34px;
	z-index: 2;
	display: block;
	width: 34px;
	height: 34px;
	line-height: 34px;
	text-align: center;
	pointer-events: none;
}
.form-group.error .help-block ul {
	padding: 0px;
	color: #fc4b6c;
}
.form-group.error .help-block ul li {
	list-style: none;
}
.form-group.error input, .form-group.error select, .form-group.error textarea, form-control.error {
	border: 1px solid #fc4b6c;
}
.error {
	color: #fc4b6c;
}
.form-group.has-success label {
	color: #00a65a
}
.form-group.has-success .form-control, .form-group.has-success .input-group-addon {
	border-color: #00a65a;
	box-shadow: none
}
.form-group.has-success .help-block {
	color: #00a65a
}
.form-group.has-warning label {
	color: #f39c12
}
.form-group.has-warning .form-control, .form-group.has-warning .input-group-addon {
	border-color: #f39c12;
	box-shadow: none
}
.form-group.has-warning .help-block {
	color: #f39c12
}
.form-group.has-error label {
	color: #dd4b39
}
.form-group.has-error .form-control, .form-group.has-error .input-group-addon {
	border-color: #dd4b39;
	box-shadow: none
}
.form-group.has-error .help-block {
	color: #dd4b39
}
.input-group .input-group-addon {
	border-radius: 0;
	border-color: #d2d6de;
	background-color: #fff
}
.btn-group-vertical .btn.btn-flat:first-of-type, .btn-group-vertical .btn.btn-flat:last-of-type {
	border-radius: 0
}
.icheck>label {
	padding-left: 0
}
.form-control-feedback.fa {
	line-height: 34px
}
.input-lg+.form-control-feedback.fa, .input-group-lg+.form-control-feedback.fa, .form-group-lg .form-control+.form-control-feedback.fa {
	line-height: 46px
}
.input-sm+.form-control-feedback.fa, .input-group-sm+.form-control-feedback.fa, .form-group-sm .form-control+.form-control-feedback.fa {
	line-height: 30px
}
.progress, .progress>.progress-bar {
	-webkit-box-shadow: none;
	box-shadow: none;
	height: 5px;
}
.pro-sty1 .progress, .pro-sty1 .progress>.progress-bar {
	height: 12px;
}
.progress, .progress>.progress-bar, .progress .progress-bar, .progress>.progress-bar .progress-bar {
	border-radius: 1px
}
.pro-sty1 .progress, .progress>.progress-bar, .pro-sty1 .progress .progress-bar, .pro-sty1 .progress>.progress-bar .progress-bar {
	border-radius: 10px
}
.progress.sm, .progress-sm {
	height: 10px
}
.progress.sm, .progress-sm, .progress.sm .progress-bar, .progress-sm .progress-bar {
	border-radius: 1px
}
.progress.xs, .progress-xs {
	height: 7px
}
.progress.xs, .progress-xs, .progress.xs .progress-bar, .progress-xs .progress-bar {
	border-radius: 1px
}
.progress.xxs, .progress-xxs {
	height: 3px
}
.progress.xxs, .progress-xxs, .progress.xxs .progress-bar, .progress-xxs .progress-bar {
	border-radius: 1px
}
.progress.vertical {
	position: relative;
	width: 30px;
	height: 200px;
	display: inline-block;
	margin-right: 10px
}
.progress.vertical>.progress-bar {
	width: 100%;
	position: absolute;
	bottom: 0
}
.progress.vertical.sm, .progress.vertical.progress-sm {
	width: 20px
}
.progress.vertical.xs, .progress.vertical.progress-xs {
	width: 10px
}
.progress.vertical.xxs, .progress.vertical.progress-xxs {
	width: 3px
}
.progress-group .progress-text {
	font-weight: 600
}
.progress-group .progress-number {
	float: right
}
.table tr>td .progress {
	margin: 0
}
.progress-bar-light-blue, .progress-bar-primary {
	background-color: #3c8dbc
}
.progress-striped .progress-bar-light-blue, .progress-striped .progress-bar-primary {
	background-image: -webkit-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent)
}
.progress-bar-green, .progress-bar-success {
	background-color: #00a65a
}
.progress-striped .progress-bar-green, .progress-striped .progress-bar-success {
	background-image: -webkit-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent)
}
.progress-bar-aqua, .progress-bar-info {
	background-color: #008cd3
}
.progress-striped .progress-bar-aqua, .progress-striped .progress-bar-info {
	background-image: -webkit-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent)
}
.progress-bar-yellow, .progress-bar-warning {
	background-color: #f39c12
}
.progress-striped .progress-bar-yellow, .progress-striped .progress-bar-warning {
	background-image: -webkit-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent)
}
.progress-bar-red, .progress-bar-danger {
	background-color: #dd4b39
}
.progress-striped .progress-bar-red, .progress-striped .progress-bar-danger {
	background-image: -webkit-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent)
}
.small-box .mini-stat-info {
	font-size: 14px;
	padding-top: 2px;
}
.small-box .mini-stat-icon {
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 50%;
	color: #ffffff;
	display: inline-block;
	float: left;
	font-size: 30px;
	height: 60px;
	line-height: 60px;
	margin-right: 10px;
	text-align: center;
	width: 60px;
}
.small-box {
	border-radius: 5px;
	position: relative;
	display: block;
	margin-bottom: 30px;
	box-shadow: 0 1px 1px rgba(0,0,0,0.1)
}
.small-box>.inner {
	padding: 15px
}
.small-box>.inner h2 {
	font-size: 25px;
	margin-bottom: 0px;
}
.small-box>.inner h3 {
	font-size: 40px;
	margin: 30px 0 0 0;
	font-weight: 500;
}
.small-box>.small-box-footer {
	position: relative;
	text-align: center;
	padding: 3px 0;
	color: #fff;
	color: rgba(255,255,255,0.8);
	display: block;
	z-index: 10;
	background: rgba(0,0,0,0.1);
	text-decoration: none
}
.small-box>.small-box-footer:hover {
	color: #fff;
	background: rgba(0,0,0,0.15)
}
.small-box h3 {
	font-size: 38px;
	font-weight: bold;
	margin: 0 0 10px 0;
	white-space: nowrap;
	padding: 0
}
.small-box p {
	font-size: 15px;
	margin-bottom: 0;
}
.small-box p>small {
	display: block;
	color: #f9f9f9;
	font-size: 13px;
	margin-top: 5px
}
.small-box h3, .small-box p {
	z-index: 5
}
.small-box .icon {
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
	position: absolute;
	top: 0px;
	right: 15px;
	z-index: 0;
	font-size: 90px;
	color: #fff;
}
.small-box:hover {
	text-decoration: none;
	color: #f9f9f9
}
/*image hovers */
.col-img-hover {
	position: relative;
	width: 100%;
	padding: 0;
	margin: 0px;
	float: left;
	border: 0;
}
.img-hover-st-1 {
	position: relative;
	width: 100%;
	height: 270px;
	padding: 0px;
	margin: 0px;
	float: left;
	overflow: hidden;
}
.img-hover-st-1 .text {
	position: absolute;
	padding: 0px;
	margin: 0px;
	float: left;
	width: 100%;
	height: 270px;
	left: 0px;
	top: 0px;
	z-index: 10;
	background-color: #0077d3;
	transition: left .3s ease-in-out;
}
.img-hover-st-1 .text.green {
	background-color: #00a65a;
}
.img-hover-st-1 .text.orange {
	background-color: #ff851b;
}
.img-hover-st-1 .text.yellow {
	background-color: #f39c12;
}
.img-hover-st-1 .imgbox {
	position: absolute;
	padding: 0px;
	margin: 0px;
	float: left;
	width: 100%;
	height: 270px;
	left: 0px;
	top: 0px;
	z-index: 100;
	transition: top .3s ease-in-out;
}
.imgbox img {
	width: 100%;
}
.img-hover-st-1 .text .title {
	color: #fff;
	margin: 30px 0 0 20px;
}
.img-hover-st-1 .text p {
	color: #fff;
	font-weight: normal;
	margin: 5px 15px 0 19px;
}
.img-hover-st-1 .text a {
	color: #FFF;
	margin: 15px 15px 0 19px;
}
.img-hover-st-1 .text a:hover {
	color: #161616;
}
.img-hover-st-1:hover .imgbox {
	position: absolute;
	top: 270px;
	transition: top .3s ease-in-out;
}
/*style 2*/
.img-hover-st-2 {
	opacity: 1;
	width: 100%;
	height: 270px;
	margin: 0px;
	position: relative;
	left: 0px;
	top: 0px;
	margin: 0px;
	overflow: hidden;
	-webkit-transition: opacity .25s ease-in-out;
	-moz-transition: opacity .25s ease-in-out;
	-ms-transition: opacity .25s ease-in-out;
	-o-transition: opacity .25s ease-in-out;
	transition: opacity .25s ease-in-out;
}
.img-hover-st-2 .imgbox {
	padding: 0px;
	margin: 0px;
	float: left;
	width: 100%;
	height: 270px;
}
.img-hover-st-2 .text {
	position: absolute;
	background: #000;
	width: 100%;
	height: 270px;
	text-align: center;
	color: #fff;
	top: 0;
	left: 0;
	opacity: 0;
	-webkit-transition: opacity .50s ease-in-out;
	-moz-transition: opacity .50s ease-in-out;
	-ms-transition: opacity .50s ease-in-out;
	-o-transition: opacity .50s ease-in-out;
	transition: opacity .50s ease-in-out;
}
.img-hover-st-2 .text:hover {
	opacity: 1;
	-webkit-transition: opacity .25s ease-in-out;
	-moz-transition: opacity .25s ease-in-out;
	-ms-transition: opacity .25s ease-in-out;
	-o-transition: opacity .25s ease-in-out;
	transition: opacity .25s ease-in-out;
	/* background: url(./img/img11.png) left top repeat; */
}
.img-hover-st-2 .text a.hov_circle {
	position: absolute;
	width: 50px;
	height: 50px;
	left: 63px;
	top: 0px;
	padding: 0px;
	margin: 0px;
	background-color: #0077d3;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	overflow: hidden;
	transition-property: background-color, color;
	transition: top .3s ease-in-out;
	transition-timing-function: ease-out;
}
.img-hover-st-2 .text a.hov_circle:hover, .img-hover-st-2 .text a.hov_circle.two:hover {
	background-color: #fff;
	transition-property: background-color, color;
	transition-duration: 0.3s;
	transition-timing-function: ease-out;
}
.img-hover-st-2 .text a.hov_circle:hover i, .img-hover-st-2 .text a.hov_circle.two:hover i {
	color: #272727;
	transition-property: color;
	transition-duration: 0.3s;
	transition-timing-function: ease-out;
}
.img-hover-st-2 .text a.hov_circle.two {
	left: 118px;
	top: 0px;
	transition: top .5s ease-in-out;
	background-color: #8dba13;
}
.img-hover-st-2 .text a.hov_circle i {
	color: #fff;
	font-size: 20px;
	text-align: center;
	line-height: 50px;
}
.img-hover-st-2 .text:hover .hov_circle {
	transition: top .3s ease-in-out;
	left: 65px;
	top: 110px;
}
.img-hover-st-2 .text:hover .hov_circle.two {
	transition: top .5s ease-in-out;
	left: 122px;
	top: 110px;
}
/*style 3*/
.img-hover-st-3 {
	opacity: 1;
	width: 100%;
	height: 270px;
	margin: 0px;
	position: relative;
	left: 0px;
	top: 0px;
	margin: 0px;
	overflow: hidden;
	-webkit-transition: opacity .25s ease-in-out;
	-moz-transition: opacity .25s ease-in-out;
	-ms-transition: opacity .25s ease-in-out;
	-o-transition: opacity .25s ease-in-out;
	transition: opacity .25s ease-in-out;
}
.img-hover-st-3 .imgbox {
	padding: 0px;
	margin: 0px;
	float: left;
	width: 100%;
	height: 255px;
}
.img-hover-st-3 .text {
	width: 100%;
	height: 270px;
	position: absolute;
	background: #000;
	color: #fff;
	top: 0;
	left: 0;
	opacity: 0;
	overflow: hidden;
	-webkit-transition: opacity .50s ease-in-out;
	-moz-transition: opacity .50s ease-in-out;
	-ms-transition: opacity .50s ease-in-out;
	-o-transition: opacity .50s ease-in-out;
	transition: opacity .50s ease-in-out;
}
.img-hover-st-3 .text:hover {
	opacity: 1;
	-webkit-transition: opacity .25s ease-in-out;
	-moz-transition: opacity .25s ease-in-out;
	-ms-transition: opacity .25s ease-in-out;
	-o-transition: opacity .25s ease-in-out;
	transition: opacity .25s ease-in-out;
	/* background: url(./img/img11.png) left top repeat; */
}
.img-hover-st-3 .text h3 {
	position: absolute;
	color: #fff;
	left: 5px;
	top: 275px;
	margin: 40px 0 0 19px;
	transition: top .4s ease-in-out;
}
.img-hover-st-3 .text p {
	position: absolute;
	color: #fff;
	font-size: 14px;
	font-weight: normal;
	left: 5px;
	line-height: 18px;
	top: 255px;
	text-align: left;
	margin: 40px 0 0 20px;
	transition: top .6s ease-in-out;
}
.img-hover-st-3 .text a {
	position: absolute;
	font-weight: normal;
	left: 0px;
	top: 320px;
	text-align: left;
	margin: 30px 0 0 25px;
	transition: top .7s ease-in-out;
	overflow: hidden;
}
.img-hover-st-3 .text:hover h3 {
	position: absolute;
	left: 5px;
	top: 20px;
	transition: top .4s ease-in-out;
}
.img-hover-st-3 .text:hover p {
	position: absolute;
	left: 5px;
	top: 50px;
	transition: top .6s ease-in-out;
}
.img-hover-st-3 .text:hover a {
	position: absolute;
	left: 0px;
	top: 136px;
	transition: top .7s ease-in-out;
}
/*style 4*/
.img-hover-st-4 {
	position: relative;
	width: 100%;
	height: 270px;
	padding: 0px;
	margin: 0px;
	float: left;
	overflow: hidden;
}
.img-hover-st-4 .imgbox {
	padding: 0px;
	margin: 0px;
	float: left;
	width: 100%;
	height: 255px;
}
.img-hover-st-4 .text {
	position: absolute;
	padding: 0px;
	margin: 0px;
	float: left;
	width: 100%;
	height: 270px;
	left: 0px;
	top: 270px;
	z-index: 100;
	/* background: url(./img/img11.png) left top repeat; */
	transition: top .3s ease-in-out;
}
.img-hover-st-4 .text h3 {
	color: #fff;
	margin: 40px 0 0 20px;
}
.img-hover-st-4 .text h3 span {
	color: #fff;
	font-size: 13px;
	text-transform: none;
}
.img-hover-st-4 .text p {
	color: #fff;
	font-weight: normal;
	margin: 5px 15px 0 20px;
}
.img-hover-st-4 .text a {
	margin: 8px 15px 0 20px;
}
.img-hover-st-4:hover .text {
	position: absolute;
	top: 0px;
	transition: top .3s ease-in-out;
}
.img-hover-st-4 .container {
	border-top: 1px solid #e1e1e1;
	padding-top: 50px;
}
/*style 5*/
.img-hover-st-5 {
	position: relative;
	width: 100%;
	height: 270px;
	padding: 0px;
	margin: 0px;
	float: left;
	overflow: hidden;
}
.img-hover-st-5 .imgbox {
	padding: 0px;
	margin: 0px;
	float: left;
	width: 100%;
	height: 255px;
}
.img-hover-st-5 .text {
	position: absolute;
	padding: 0px;
	margin: 0px;
	float: left;
	width: 100%;
	height: 270px;
	left: -270px;
	top: 0px;
	z-index: 100;
	transition: left .5s ease-in-out;
	/* background: url(./img/img11.png) left top repeat; */
}
.img-hover-st-5 .text h3 {
	color: #fff;
	margin: 40px 0 0 20px;
}
.img-hover-st-5 .text p {
	color: #fff;
	font-weight: normal;
	margin: 0px 15px 0 20px;
}
.img-hover-st-5 .text a {
	margin: 15px 15px 0 20px;
}
.img-hover-st-5:hover .text {
	position: absolute;
	left: 0px;
	transition: left .5s ease-in-out;
}
 @media (max-width:767px) {
.small-box {
	text-align: center
}
.small-box .icon {
	display: none
}
.small-box p {
	font-size: 12px
}
}
.col-bor {
	border: solid 1px #dddddd;
}
.box {
	position: relative;
	border-radius: 3px;
	background: #ffffff;
	width: 100%;
	box-shadow: 0 1px 1px rgba(0,0,0,0.1)
}
.box.box-primary {
	border-top-color: #3c8dbc
}
.box.box-info {
	border-top-color: #008cd3
}
.box.box-danger {
	border-top-color: #dd4b39
}
.box.box-warning {
	border-top-color: #f39c12
}
.box.box-success {
	border-top-color: #00a65a
}
.box.box-default {
	border-top-color: #d2d6de
}
.box.collapsed-box .box-body, .box.collapsed-box .box-footer {
	display: none
}
.box ul.nav {
	display: inline;
}
.box .nav-stacked>li {
	margin: 0
}
.box .nav-stacked>li:last-of-type {
	border-bottom: none
}
.box.height-control .box-body {
	max-height: 300px;
	overflow: auto
}
.box .border-right {
	border-right: 1px solid #f4f4f4
}
.box .border-left {
	border-left: 1px solid #f4f4f4
}
.box.box-solid {
	border-top: 0
}
.box.box-solid>.box-header .btn.btn-default {
	background: transparent
}
.box.box-solid>.box-header .btn:hover, .box.box-solid>.box-header a:hover {
	background: rgba(0,0,0,0.1)
}
.box.box-solid.box-default {
	border: 1px solid #d2d6de
}
.box.box-solid.box-default>.box-header {
	color: #444;
	background: #d2d6de;
	background-color: #d2d6de
}
.box.box-solid.box-default>.box-header a, .box.box-solid.box-default>.box-header .btn {
	color: #444
}
.box.box-solid.box-primary {
	border: 1px solid #3c8dbc
}
.box.box-solid.box-primary>.box-header {
	color: #fff;
	background: #3c8dbc;
	background-color: #3c8dbc
}
.box.box-solid.box-primary>.box-header a, .box.box-solid.box-primary>.box-header .btn {
	color: #fff
}
.box.box-solid.box-info {
	border: 1px solid #008cd3
}
.box.box-solid.box-info>.box-header {
	color: #fff;
	background: #008cd3;
	background-color: #008cd3
}
.box.box-solid.box-info>.box-header a, .box.box-solid.box-info>.box-header .btn {
	color: #fff
}
.box.box-solid.box-danger {
	border: 1px solid #dd4b39
}
.box.box-solid.box-danger>.box-header {
	color: #fff;
	background: #dd4b39;
	background-color: #dd4b39
}
.box.box-solid.box-danger>.box-header a, .box.box-solid.box-danger>.box-header .btn {
	color: #fff
}
.box.box-solid.box-warning {
	border: 1px solid #f39c12
}
.box.box-solid.box-warning>.box-header {
	color: #fff;
	background: #f39c12;
	background-color: #f39c12
}
.box.box-solid.box-warning>.box-header a, .box.box-solid.box-warning>.box-header .btn {
	color: #fff
}
.box.box-solid.box-success {
	border: 1px solid #00a65a
}
.box.box-solid.box-success>.box-header {
	color: #fff;
	background: #00a65a;
	background-color: #00a65a
}
.box.box-solid.box-success>.box-header a, .box.box-solid.box-success>.box-header .btn {
	color: #fff
}
.box.box-solid>.box-header>.box-tools .btn {
	border: 0;
	box-shadow: none
}
.box.box-solid[class*='bg']>.box-header {
	color: #fff
}
.box .box-group>.box {
	margin-bottom: 5px
}
.box .knob-label {
	text-align: center;
	color: #333;
	font-weight: 100;
	font-size: 12px;
	margin-bottom: 0.3em
}
.box>.overlay, .overlay-wrapper>.overlay, .box>.loading-img, .overlay-wrapper>.loading-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}
.box .overlay, .overlay-wrapper .overlay {
	z-index: 50;
	background: rgba(255,255,255,0.7);
	border-radius: 3px
}
.box .overlay>.fa, .overlay-wrapper .overlay>.fa {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -15px;
	margin-top: -15px;
	color: #000;
	font-size: 30px
}
.box .overlay.dark, .overlay-wrapper .overlay.dark {
	background: rgba(0,0,0,0.5)
}
.box-header:before, .box-body:before, .box-footer:before, .box-header:after, .box-body:after, .box-footer:after {
	content: " ";
	display: table
}
.box-header:after, .box-body:after, .box-footer:after {
	clear: both
}
.box-header {
	color: #444;
	display: block;
	padding: 10px;
	position: relative
}
.box-header.with-border {
	border-bottom: 1px solid #f4f4f4;
	padding-top: 20px;
}
.collapsed-box .box-header.with-border {
	border-bottom: none
}
.box-header>.fa, .box-header>.glyphicon, .box-header>.ion, .box-header .box-title {
	display: inline-block;
	font-size: 18px;
	margin: 0;
	line-height: 1
}
.box-header>.fa, .box-header>.glyphicon, .box-header>.ion {
	margin-right: 5px
}
.box-header>.box-tools {
	position: absolute;
	right: 10px;
	top: 5px
}
.box-header>.box-tools [data-toggle="tooltip"] {
	position: relative
}
.box-header>.box-tools.pull-right .dropdown-menu {
	right: 0;
	left: auto
}
.box-header>.box-tools .dropdown-menu>li>a {
	color: #444!important
}
.btn-box-tool {
	padding: 5px;
	font-size: 12px;
	background: transparent;
	color: #97a0b3
}
.open .btn-box-tool, .btn-box-tool:hover {
	color: #606c84
}
.btn-box-tool.btn:active {
	box-shadow: none
}
.box-body {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
	padding: 0px
}
.box-profile {
	padding: 10px
}
.no-header .box-body {
	border-top-right-radius: 3px;
	border-top-left-radius: 3px
}
.table thead.thead-dark {
	background: #192844;
	color: #FFF;
}
.table thead.thead-light {
	background: #e9ecef;
}
.box-body>.table {
	margin-bottom: 0
}
caption {
	padding-top: 8px;
	padding-bottom: 8px;
	color: #777;
	text-align: left;
}
caption.bottom {
	caption-side: top;
}
#example1 caption {
	display: none;
}
.box-body .fc {
	margin-top: 5px
}
.box-body .full-width-chart {
	margin: -19px
}
.box-body.no-padding .full-width-chart {
	margin: -9px
}
.box-body .box-pane {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 3px
}
.box-body .box-pane-right {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 0
}
.box-footer {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
	border-top: 1px solid #f4f4f4;
	padding: 10px;
	background-color: #fff
}
.chart-legend {
	margin: 10px 0
}
.card-body {
	padding: 1.25rem;
}
.card-header {
	background-color: transparent
}
.progress.progress-sm {
 height: .5rem !important;
}
.bg-gradient-x-danger {
	background-image: -webkit-gradient(linear, left top, right top, from(#FF394F), to(#FF8090));
	background-image: -webkit-linear-gradient(left, #FF394F 0, #FF8090 100%);
	background-image: -moz-linear-gradient(left, #FF394F 0, #FF8090 100%);
	background-image: -o-linear-gradient(left, #FF394F 0, #FF8090 100%);
	background-image: linear-gradient(to right, #FF394F 0, #FF8090 100%);
	background-repeat: repeat-x;
}
@media (max-width:991px) {
.chart-legend>li {
	float: left;
	margin-right: 10px
}
}
.box-comments {
	background: #f7f7f7
}
.box-comments .box-comment {
	padding: 8px 0;
	border-bottom: 1px solid #eee
}
.box-comments .box-comment:before, .box-comments .box-comment:after {
	content: " ";
	display: table
}
.box-comments .box-comment:after {
	clear: both
}
.box-comments .box-comment:last-of-type {
	border-bottom: 0
}
.box-comments .box-comment:first-of-type {
	padding-top: 0
}
.box-comments .box-comment img {
	float: left
}
.box-comments .comment-text {
	margin-left: 40px;
	color: #555
}
.box-comments .username {
	color: #444;
	display: block;
	font-weight: 600
}
.box-comments .text-muted {
	font-weight: 400;
	font-size: 12px
}
.todo-list {
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: auto
}
.todo-list>li {
	border-radius: 2px;
	padding: 10px;
	background: #f4f4f4;
	margin-bottom: 2px;
	border-left: 2px solid #e6e7e8;
	color: #444
}
.todo-list>li:last-of-type {
	margin-bottom: 0
}
.todo-list>li>input[type='checkbox'] {
	margin: 0 10px 0 5px
}
.todo-list>li .text {
	display: inline-block;
	margin-left: 5px;
	font-weight: 600
}
.todo-list>li .label {
	margin-left: 10px;
	font-size: 9px
}
span.label {
	border-radius: 3px;
	padding: 0 6px;
	font-size: 13px;
}
.todo-list>li .tools {
	display: none;
	float: right;
	color: #dd4b39
}
.todo-list>li .tools>.fa, .todo-list>li .tools>.glyphicon, .todo-list>li .tools>.ion {
	margin-right: 5px;
	cursor: pointer
}
.todo-list>li:hover .tools {
	display: inline-block
}
.todo-list>li.done {
	color: #999
}
.todo-list>li.done .text {
	text-decoration: line-through;
	font-weight: 500
}
.todo-list>li.done .label {
	background: #d2d6de !important
}
.todo-list .danger {
	border-left-color: #dd4b39
}
.todo-list .warning {
	border-left-color: #f39c12
}
.todo-list .info {
	border-left-color: #008cd3
}
.todo-list .success {
	border-left-color: #00a65a
}
.todo-list .primary {
	border-left-color: #3c8dbc
}
.todo-list .handle {
	display: inline-block;
	cursor: move;
	margin: 0 5px
}
.chat {
	padding: 5px 20px 5px 10px
}
.chat .item {
	margin-bottom: 10px
}
.chat .item:before, .chat .item:after {
	content: " ";
	display: table
}
.chat .item:after {
	clear: both
}
.chat .item>img {
	width: 40px;
	height: 40px;
	border: 2px solid transparent;
	border-radius: 50%
}
.chat .item>.online {
	border: 2px solid #00a65a
}
.chat .item>.offline {
	border: 2px solid #dd4b39
}
.chat .item>.message {
	margin-left: 55px;
	margin-top: -40px
}
.chat .item>.message>.name {
	display: block;
	font-weight: 600
}
.chat .item>.attachment {
	border-radius: 3px;
	background: #f4f4f4;
	margin-left: 65px;
	margin-right: 15px;
	padding: 10px
}
.chat .item>.attachment>h4 {
	margin: 0 0 5px 0;
	font-weight: 600;
	font-size: 14px
}
.chat .item>.attachment>p, .chat .item>.attachment>.filename {
	font-weight: 600;
	font-size: 13px;
	font-style: italic;
	margin: 0
}
.chat .item>.attachment:before, .chat .item>.attachment:after {
	content: " ";
	display: table
}
.chat .item>.attachment:after {
	clear: both
}
.box-input {
	max-width: 200px
}
.modal .panel-body {
	color: #444
}
.info-box {
	display: block;
	min-height: 90px;
	background: #fff;
	width: 100%;
	box-shadow: 0 1px 2px rgba(0,0,0,0.15);
	border-radius: 5px;
	margin-bottom: 30px;
	padding: 15px 5px;
	transition: .5s;
}
.info-box:hover {
	-webkit-transform: translateY(-6px);
	transform: translateY(-6px);
	-moz-box-shadow: 0 20px 20px rgba(0,0,0,.1);
	webkit-box-shadow: 0 0 25px -5px #9e9c9e;
	box-shadow: 0 0 25px -5px #9e9c9e;
}
.box-rounded {
	border-radius: 5px;
}
#ex1Slider .slider-selection {
	background: #BABABA;
}
.info-box small {
	font-size: 14px
}
.info-box .progress {
	background: rgba(0,0,0,0.15);
	margin: 5px 0px;
	height: 6px;
	border-radius: 3px !important;
}
.info-box .progress, .info-box .progress .progress-bar {
	border-radius: 0
}
.info-box-icon {
	border-top-left-radius: 2px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 2px;
	display: block;
	float: right;
	height: 65px;
	width: 65px;
	text-align: center;
	font-size: 28px;
	line-height: 65px;
	background: rgba(0,0,0,0.2);
	border-radius: 50%;
}
.info-box-icon.bg-transparent {
	background-color: transparent;
	height: auto;
	width: auto;
	font-size: 35px;
	line-height: 0;
}
.info-box-icon>img {
	max-width: 100%
}
.info-box-content {
	padding: 5px 0px;
}
.info-widget-text {
	line-height: 100%;
}
.info-widget-text span {
	font-size: 14px;
	color: #b1b1b1;
}
.info-box-number {
	display: block;
	font-weight: normal;
	font-size: 20px;
	color: #0077d3;
}
.progress-description, .info-box-text {
	display: block;
	font-size: 13px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #999;
}
.text-muted {
	font-size: 14px;
	margin: 0;
	color: #8a8b8c;
}
span.text-muted {
	color: #cdcdcd !important;
}
small.text-muted {
	font-size: 75%;
}
.info-box-more {
	display: block
}
.progress-description {
	margin: 0
}
.timeline {
	position: relative;
	margin: 0 0 30px 0;
	padding: 0;
	list-style: none
}
.timeline:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	width: 0px;
	background: #ddd;
	left: 31px;
	margin: 0;
	border-radius: 2px
}
.timeline>li {
	position: relative;
	margin-right: 10px;
	margin-bottom: 15px
}
.timeline>li:before, .timeline>li:after {
	content: " ";
	display: table
}
.timeline>li:after {
	clear: both
}
.timeline>li>.timeline-item {
	-webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.1);
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
	border-radius: 3px;
	margin-top: 0;
	background: #fff;
	color: #444;
	margin-left: 60px;
	margin-right: 15px;
	padding: 0;
	position: relative
}
.timeline>li>.timeline-item>.time {
	color: #999;
	float: right;
	padding: 10px;
	font-size: 12px
}
.timeline>li>.timeline-item>.timeline-header {
	margin: 0;
	color: #555;
	border-bottom: 1px solid #f4f4f4;
	padding: 10px;
	font-size: 16px;
	line-height: 1.1
}
.timeline>li>.timeline-item>.timeline-header>a {
	font-weight: 600
}
.timeline>li>.timeline-item>.timeline-body, .timeline>li>.timeline-item>.timeline-footer {
	padding: 10px
}
.timeline>li>.fa, .timeline>li>.glyphicon, .timeline>li>.ion {
	width: 30px;
	height: 30px;
	font-size: 15px;
	line-height: 30px;
	position: absolute;
	color: #666;
	background: #d2d6de;
	border-radius: 50%;
	text-align: center;
	left: 18px;
	top: 0
}
.timeline>.time-label>span {
	font-weight: 600;
	padding: 5px;
	display: inline-block;
	background-color: #fff;
	border-radius: 4px
}
.timeline-inverse>li>.timeline-item {
	background: #f0f0f0;
	border: 1px solid #ddd;
	-webkit-box-shadow: none;
	box-shadow: none
}
.timeline-inverse>li>.timeline-item>.timeline-header {
	border-bottom-color: #ddd
}
.btn {
	border-radius: 5px;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 1px solid transparent;
	font-size: 14px;
	padding: 10px 20px;
}
.btn.uppercase {
	text-transform: uppercase
}
.btn.btn-flat {
	border-radius: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	border-width: 1px
}
.btn:active {
	-webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
	-moz-box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
	box-shadow: inset 0 3px 5px rgba(0,0,0,0.125)
}
.btn:focus {
	outline: none
}
.btn.btn-file {
	position: relative;
	overflow: hidden
}
.btn.btn-file>input[type='file'] {
	position: absolute;
	top: 0;
	right: 0;
	min-width: 100%;
	min-height: 100%;
	font-size: 100px;
	text-align: right;
	opacity: 0;
	filter: alpha(opacity=0);
	outline: none;
	background: white;
	cursor: inherit;
	display: block
}
.btn-default {
	background-color: #f4f4f4;
	color: #444;
	border-color: #ddd
}
.btn-default:hover, .btn-default:active, .btn-default.hover {
	background-color: #e7e7e7
}
.btn-primary-2 {
	background-color: #579ddb;
	border-color: #4857d0;
	color: white;
	font-size: 12px;
	border-radius: 0;
	margin-top: -5px;
}
.btn-primary-2:hover {
	background-color: #336ea2;
	color: lightblue;
}
.btn-primary {
	background-color: #192844;
	border-color: #4857d0
}
caption button.btn-default {
	background-color: #0077d3;
	border-color: #4857d0;
	margin-right: 5px;
	color: #FFF;
}
.btn-primary:hover, .btn-primary:active, .btn-primary.hover, caption button.btn-default:hover {
	background-color: #6a79ee
}
.btn-success {
	background-color: #192844;
	border-color: #0d2044
}
.btn-success:hover, .btn-success:active, .btn-success.hover {
	background-color: #304875;
	border-color: #1b3f82;
}
.btn-info {
	background-color: #008cd3;
	border-color: #00acd6
}
.btn-info:hover, .btn-info:active, .btn-info.hover {
	background-color: #00acd6
}
.btn-danger {
	background-color: #dd4b39;
	border-color: #d73925
}
.btn-danger:hover, .btn-danger:active, .btn-danger.hover {
	background-color: #ff452e
}
.btn-warning {
	background-color: #e08e0b;
	border-color: #d48506
}
.btn-warning:hover, .btn-warning:active, .btn-warning.hover {
	background-color: #ec9812
}
.btn-outline {
	border: 1px solid #fff;
	background: transparent;
	color: #fff
}
.btn-outline:hover, .btn-outline:focus, .btn-outline:active {
	color: rgba(255,255,255,0.7);
	border-color: rgba(255,255,255,0.7)
}
.btn-link {
	-webkit-box-shadow: none;
	box-shadow: none
}
.btn[class*='bg-']:hover {
	-webkit-box-shadow: inset 0 0 100px rgba(0,0,0,0.2);
	box-shadow: inset 0 0 100px rgba(0,0,0,0.2)
}
.btn-app {
	border-radius: 3px;
	position: relative;
	padding: 15px 5px;
	margin: 0 0 10px 10px;
	min-width: 80px;
	height: 60px;
	text-align: center;
	color: #666;
	border: 1px solid #ddd;
	background-color: #f4f4f4;
	font-size: 12px
}
.btn-app>.fa, .btn-app>.glyphicon, .btn-app>.ion {
	font-size: 20px;
	display: block
}
.btn-app:hover {
	background: #f4f4f4;
	color: #444;
	border-color: #aaa
}
.btn-app:active, .btn-app:focus {
	-webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
	-moz-box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
	box-shadow: inset 0 3px 5px rgba(0,0,0,0.125)
}
.btn-app>.badge {
	position: absolute;
	top: -3px;
	right: -10px;
	font-size: 10px;
	font-weight: 400
}
.btn-rounded {
	border-radius: 30px;
}
.btn-secondary {
	background-color: #868e96;
	color: #FFF;
}
.btn-secondary:hover {
	background-color: #727b84;
	color: #FFF;
}
.btn-dark {
	background-color: #343a40;
	color: #FFF;
}
.btn-dark:hover {
	background-color: #23272b;
	color: #FFF;
}
.btn-light:hover {
	background-color: #e2e6ea;
	border: solid 1px #dae0e5;
}
.btn-outline-primary {
	border: solid 1px #007bff;
}
.btn-outline-secondary {
	border: solid 1px #868e96;
	color: #868e96
}
.btn-outline-secondary:hover {
	border: solid 1px #868e96;
	background-color: #868e96;
}
.btn-outline-success {
	border: solid 1px #28a745;
}
.btn-outline-danger {
	border: solid 1px #dc3545;
}
.btn-outline-warning {
	border: solid 1px #ffc107;
}
.btn-outline-info {
	border: solid 1px #17a2b8;
}
.btn-outline-dark {
	border: solid 1px #343a40;
}
.btn-outline-dark:hover {
	border: solid 1px #343a40;
	background: #343a40;
	color: #FFF;
}
.btn-lg {
	padding: 13px 30px;
}
.btn-nor {
	padding: 8px 18px;
}
.btn-sm {
	padding: 5px 12px;
}
.pricing-box {
	padding: 20px 0;
	border: solid 1px #eaebeb;
	border-radius: 5px;
}
.pricing-box.sty1 {
	padding: 0 0 20px 0;
}
.price {
	border-bottom: solid 1px #eaebeb;
	padding-bottom: 20px;
}
.price.sty1 {
	background: #0077d3;
	padding: 20px 0;
	color: #FFF;
}
.pricing-box.active {
	background: #0077d3;
	color: #FFF;
	border: solid 1px #0077d3;
}
.price h2 {
	font-size: 35px;
	font-weight: 600;
	margin-bottom: 0px;
}
.price small {
	font-size: 14px;
}
ul.plan-list {
	list-style: none;
	margin: 0;
	padding: 0;
}
ul.plan-list li {
	padding: 15px 0;
	border-bottom: solid 1px #eaebeb;
}
.pricing-box.active ul.plan-list li {
	border-bottom: solid 1px #6e7cef;
}
.panel-title {
	border-bottom: solid 1px #eaebeb;
	font-size: 16px;
	padding: 13px 0;
}
.panel-title a {
	color: #292b2c;
}
.panel-body {
	padding-bottom: 15px;
}
.callout {
	border-radius: 3px;
	margin: 0 0 20px 0;
	padding: 15px 30px 15px 15px;
	border-left: 0px solid #eee
}
.callout a {
	color: #fff;
	text-decoration: underline
}
.callout a:hover {
	color: #eee
}
.callout h4 {
	margin-top: 0;
	font-weight: 600
}
.callout p:last-child {
	margin-bottom: 0
}
.callout code, .callout .highlight {
	background-color: #fff
}
.callout.callout-danger {
	border-color: #c23321
}
.callout.callout-warning {
	border-color: #c87f0a
}
.callout.callout-info {
	border-color: #0097bc
}
.callout.callout-success {
	border-color: #00733e
}
.alert {
	border-radius: 3px
}
.alert h4 {
	font-weight: 600
}
.alert .icon {
	margin-right: 10px
}
.alert .close {
	color: #000;
	opacity: .2;
	filter: alpha(opacity=20)
}
.alert .close:hover {
	opacity: .5;
	filter: alpha(opacity=50)
}
.alert a {
	color: #fff;
	text-decoration: underline
}
.alert-success {
	border-color: #008d4c
}
.alert-danger, .alert-error {
	border-color: #d73925
}
.alert-warning {
	border-color: #e08e0b
}
.alert-info {
	border-color: #00acd6
}
.nav>li>a:hover, .nav>li>a:active, .nav>li>a:focus {
	color: #fff;
	background-color: transparent;
}
.nav-pills>li>a {
	border-radius: 0;
	border-top: 3px solid transparent;
	color: #444
}
.nav-pills>li>a>.fa, .nav-pills>li>a>.glyphicon, .nav-pills>li>a>.ion {
	margin-right: 5px
}
.nav-pills>li.active>a, .nav-pills>li.active>a:hover, .nav-pills>li.active>a:focus {
	border-top-color: #3c8dbc
}
.nav-pills>li.active>a {
	font-weight: 600
}
.nav-stacked>li>a {
	border-radius: 0;
	border: 0;
	color: #444;
	padding: 12px 15px 11px 15px;
	display: block;
	background-color: transparent;
}
.nav-stacked.sty1>li>a {
	padding: 12px 0px 13px 0px;
}
.nav-stacked>li.active>a, .nav-stacked>li.active>a:hover, .nav-stacked>li>a:hover {
	background-color: transparent;
	color: #0077d3;
	border: 0;
}
.nav-stacked>li.header {
	border-bottom: 1px solid #ddd;
	color: #777;
	margin-bottom: 10px;
	padding: 5px 10px;
	text-transform: uppercase
}
.nav-tabs-custom {
	margin-bottom: 20px;
	background: #fff;
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
	border-radius: 3px
}
.nav-tabs-custom>.nav-tabs {
	margin: 0;
	border-bottom-color: #f4f4f4;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px
}
.nav-tabs-custom>.nav-tabs>li {
	border-top: 3px solid transparent;
	margin-bottom: -2px;
	margin-right: 5px
}
.nav-tabs-custom>.nav-tabs>li.disabled>a {
	color: #777
}
.nav-tabs-custom>.nav-tabs>li>a {
	color: #444;
	border-radius: 0
}
.nav-tabs-custom>.nav-tabs>li>a.text-muted {
	color: #999
}
.nav-tabs-custom>.nav-tabs>li>a, .nav-tabs-custom>.nav-tabs>li>a:hover {
	background: transparent;
	margin: 0
}
.nav-tabs-custom>.nav-tabs>li>a:hover {
	color: #999
}
.nav-tabs-custom>.nav-tabs>li:not(.active)>a:hover, .nav-tabs-custom>.nav-tabs>li:not(.active)>a:focus, .nav-tabs-custom>.nav-tabs>li:not(.active)>a:active {
	border-color: transparent
}
.nav-tabs-custom>.nav-tabs>li.active {
	border-top-color: #3c8dbc
}
.nav-tabs-custom>.nav-tabs>li.active>a, .nav-tabs-custom>.nav-tabs>li.active:hover>a {
	background-color: #fff;
	color: #444
}
.nav-tabs-custom>.nav-tabs>li.active>a {
	border-top-color: transparent;
	border-left-color: #f4f4f4;
	border-right-color: #f4f4f4
}
.nav-tabs-custom>.nav-tabs>li:first-of-type {
	margin-left: 0
}
.nav-tabs-custom>.nav-tabs>li:first-of-type.active>a {
	border-left-color: transparent
}
.nav-tabs-custom>.nav-tabs.pull-right {
	float: none !important
}
.nav-tabs-custom>.nav-tabs.pull-right>li {
	float: right
}
.nav-tabs-custom>.nav-tabs.pull-right>li:first-of-type {
	margin-right: 0
}
.nav-tabs-custom>.nav-tabs.pull-right>li:first-of-type>a {
	border-left-width: 1px
}
.nav-tabs-custom>.nav-tabs.pull-right>li:first-of-type.active>a {
	border-left-color: #f4f4f4;
	border-right-color: transparent
}
.nav-tabs-custom>.nav-tabs>li.header {
	line-height: 35px;
	padding: 0 10px;
	font-size: 20px;
	color: #444
}
.nav-tabs-custom>.nav-tabs>li.header>.fa, .nav-tabs-custom>.nav-tabs>li.header>.glyphicon, .nav-tabs-custom>.nav-tabs>li.header>.ion {
	margin-right: 5px
}
.nav-tabs-custom>.tab-content {
	background: #fff;
	padding: 10px;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px
}
.nav-tabs-custom .dropdown.open>a:active, .nav-tabs-custom .dropdown.open>a:focus {
	background: transparent;
	color: #999
}
.nav-tabs-custom.tab-primary>.nav-tabs>li.active {
	border-top-color: #3c8dbc
}
.nav-tabs-custom.tab-info>.nav-tabs>li.active {
	border-top-color: #008cd3
}
.nav-tabs-custom.tab-danger>.nav-tabs>li.active {
	border-top-color: #dd4b39
}
.nav-tabs-custom.tab-warning>.nav-tabs>li.active {
	border-top-color: #f39c12
}
.nav-tabs-custom.tab-success>.nav-tabs>li.active {
	border-top-color: #00a65a
}
.nav-tabs-custom.tab-default>.nav-tabs>li.active {
	border-top-color: #d2d6de
}
.bg-secondary {
	background-color: #868e96 !important;
}
.bg-dark {
	background-color: #000000 !important;
}
.pagination {
	float: right;
}
.pagination>li>a {
	position: relative;
	float: left;
	padding: 6px 15px;
	margin-left: -1px;
	line-height: 1.42857143;
	color: #337ab7;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid #ddd;
}
.pagination>li.active a {
	z-index: 3;
	color: #fff;
	cursor: default;
	background-color: #337ab7;
	border-color: #337ab7;
}
.pagination.pagination-flat>li>a {
	border-radius: 0 !important
}
.products-list {
	list-style: none;
	margin: 0;
	padding: 5px 15px;
}
.products-list>.item {
	border-radius: 3px;
	-webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.1);
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
	padding: 12px 0 13px 0;
	background: #fff
}
.products-list>.item:before, .products-list>.item:after {
	content: " ";
	display: table
}
.products-list>.item:after {
	clear: both
}
.products-list .product-img {
	float: left
}
.products-list .product-img img {
	width: 50px;
	height: 50px
}
.products-list .product-info {
	margin-left: 60px
}
.products-list .product-title {
	font-weight: 600
}
.products-list .product-description {
	display: block;
	color: #999;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis
}
.products-list .product-description a {
	color: #999;
}
.products-list .product-description a:hover {
	color: #666;
}
.product-list-in-box>.item {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 0;
	border-bottom: 1px solid #8f8f8f;
}
.product-list-in-box>.item:last-of-type {
	border-bottom-width: 0
}
.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
	/*border-top: 1px solid #8f8f8f;*/
}
.table>thead>tr>th {
	border-bottom: 2px solid #8f8f8f;
}
.table tr td .progress {
	margin-top: 5px
}
.table-bordered {
	border: 1px solid #8f8f8f;
}
.table-bordered>thead>tr>th, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>tbody>tr>td, .table-bordered>tfoot>tr>td {
	border: 1px solid #8f8f8f;
}
.table-bordered>thead>tr>th, .table-bordered>thead>tr>td {
	border-bottom-width: 2px
}
.table.no-border, .table.no-border td, .table.no-border th {
	border: 0
}
table.text-center, table.text-center td, table.text-center th {
	text-align: center
}
.table.align th {
	text-align: left
}
.table.align td {
	text-align: right
}
.label-default {
	background-color: #d2d6de;
	color: #444
}
.direct-chat .box-body {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	position: relative;
	overflow-x: hidden;
	padding: 0
}
.direct-chat.chat-pane-open .direct-chat-contacts {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0)
}
.direct-chat-messages {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	padding: 10px;
	height: 290px;
	overflow: auto
}
.scrollbar {
	background-color: #F5F5F5;
	float: left;
	height: 300px;
	margin-bottom: 25px;
	margin-left: 22px;
	margin-top: 40px;
	width: 65px;
	overflow-y: scroll;
}
.force-overflow {
	min-height: 450px;
}
.direct-chat-msg, .direct-chat-text {
	display: block
}
.direct-chat-msg {
	margin-bottom: 10px
}
.direct-chat-msg:before, .direct-chat-msg:after {
	content: " ";
	display: table
}
.direct-chat-msg:after {
	clear: both
}
.direct-chat-messages, .direct-chat-contacts {
	-webkit-transition: -webkit-transform .5s ease-in-out;
	-moz-transition: -moz-transform .5s ease-in-out;
	-o-transition: -o-transform .5s ease-in-out;
	transition: transform .5s ease-in-out
}
.direct-chat-text {
	border-radius: 5px;
	position: relative;
	padding: 5px 10px;
	background: #d8f0ff;
	border: 1px solid #d8f0ff;
	margin: 5px 0 0 50px;
	color: #444
}
.direct-chat-text:after, .direct-chat-text:before {
	position: absolute;
	right: 100%;
	top: 15px;
	border: solid transparent;
	border-right-color: #d8f0ff;
	content: ' ';
	height: 0;
	width: 0;
	pointer-events: none
}
.direct-chat-text:after {
	border-width: 5px;
	margin-top: -5px
}
.direct-chat-text:before {
	border-width: 6px;
	margin-top: -6px
}
.right .direct-chat-text {
	margin-right: 50px;
	margin-left: 0
}
.right .direct-chat-text:after, .right .direct-chat-text:before {
	right: auto;
	left: 100%;
	border-right-color: transparent;
	border-left-color: #d2d6de
}
.direct-chat-img {
	border-radius: 50%;
	float: left;
	width: 40px;
	height: 40px
}
.right .direct-chat-img {
	float: right
}
.direct-chat-info {
	display: block;
	margin-bottom: 2px;
	font-size: 12px
}
.direct-chat-name {
	font-weight: 600
}
.direct-chat-timestamp {
	color: #999
}
.direct-chat-contacts-open .direct-chat-contacts {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0)
}
.direct-chat-contacts {
	-webkit-transform: translate(101%, 0);
	-ms-transform: translate(101%, 0);
	-o-transform: translate(101%, 0);
	transform: translate(101%, 0);
	position: absolute;
	top: 0;
	bottom: 0;
	height: 250px;
	width: 100%;
	background: #222d32;
	color: #fff;
	overflow: auto
}
.contacts-list>li {
	border-bottom: 1px solid rgba(0,0,0,0.2);
	padding: 10px;
	margin: 0
}
.contacts-list>li:before, .contacts-list>li:after {
	content: " ";
	display: table
}
.contacts-list>li:after {
	clear: both
}
.contacts-list>li:last-of-type {
	border-bottom: none
}
.contacts-list-img {
	border-radius: 50%;
	width: 40px;
	float: left
}
.contacts-list-info {
	margin-left: 45px;
	color: #fff
}
.contacts-list-name, .contacts-list-status {
	display: block
}
.contacts-list-name {
	font-weight: 600
}
.contacts-list-status {
	font-size: 12px
}
.contacts-list-date {
	color: #aaa;
	font-weight: normal
}
.contacts-list-msg {
	color: #999
}
.direct-chat-danger .right>.direct-chat-text {
	background: #dd4b39;
	border-color: #dd4b39;
	color: #fff
}
.direct-chat-danger .right>.direct-chat-text:after, .direct-chat-danger .right>.direct-chat-text:before {
	border-left-color: #dd4b39
}
.direct-chat-primary .right>.direct-chat-text {
	background: #3c8dbc;
	border-color: #3c8dbc;
	color: #fff
}
.direct-chat-primary .right>.direct-chat-text:after, .direct-chat-primary .right>.direct-chat-text:before {
	border-left-color: #3c8dbc
}
.direct-chat-warning .right>.direct-chat-text {
	background: #f6f6f6;
	border-color: #f6f6f6;
}
.direct-chat-warning .right>.direct-chat-text:after, .direct-chat-warning .right>.direct-chat-text:before {
	border-left-color: #f6f6f6
}
.direct-chat-info .right>.direct-chat-text {
	background: #008cd3;
	border-color: #008cd3;
	color: #fff
}
.direct-chat-info .right>.direct-chat-text:after, .direct-chat-info .right>.direct-chat-text:before {
	border-left-color: #008cd3
}
.direct-chat-success .right>.direct-chat-text {
	background: #00a65a;
	border-color: #00a65a;
	color: #fff
}
.direct-chat-success .right>.direct-chat-text:after, .direct-chat-success .right>.direct-chat-text:before {
	border-left-color: #00a65a
}
.users-list>li {
	width: 25%;
	float: left;
	padding: 10px;
	text-align: center
}
.users-list>li img {
	border-radius: 50%;
	max-width: 100%;
	height: auto
}
.users-list>li>a:hover, .users-list>li>a:hover .users-list-name {
	color: #999
}
.users-list-name, .users-list-date {
	display: block
}
.users-list-name {
	font-weight: 600;
	color: #444;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis
}
.users-list-date {
	color: #999;
	font-size: 12px
}
.carousel-control.left, .carousel-control.right {
	background-image: none
}
.carousel-control>.fa {
	font-size: 40px;
	position: absolute;
	top: 50%;
	z-index: 5;
	display: inline-block;
	margin-top: -20px
}
.modal {
	background: rgba(0,0,0,0.3)
}
.modal-content {
	border-radius: 0;
	/*-webkit-box-shadow: 0 2px 3px rgba(0,0,0,0.125);
	box-shadow: 0 2px 3px rgba(0,0,0,0.125);*/
	border: 0
}
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	/*transition: opacity 0.5s ease-in-out;*/
}
.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}
.modal-container {
	width: 300px;
	margin: 0px auto;
	padding: 20px 30px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.5s ease-in-out;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}
.modal-enter {
	opacity: 0;
}
.modal-leave-active {
	opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
@media (min-width:768px) {
.modal-content {
	/*-webkit-box-shadow: 0 2px 3px rgba(0,0,0,0.125);
	box-shadow: 0 2px 3px rgba(0,0,0,0.125)*/
}
}
.modal-header {
	border-bottom-color: #f4f4f4
}
.modal-footer {
	border-top-color: #f4f4f4
}
.modal-primary .modal-header, .modal-primary .modal-footer {
	border-color: #307095
}
.modal-warning .modal-header, .modal-warning .modal-footer {
	border-color: #c87f0a
}
.modal-info .modal-header, .modal-info .modal-footer {
	border-color: #0097bc
}
.modal-success .modal-header, .modal-success .modal-footer {
	border-color: #00733e
}
.modal-danger .modal-header, .modal-danger .modal-footer {
	border-color: #c23321
}
.box-widget {
	border: none;
	position: relative
}
.widget-user .widget-user-header {
	padding: 20px;
	height: 150px;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px
}
.user-profile-box {
	padding: 60px 20px 50px 20px;
	border-radius: 3px;
	/* background: url(./img/user-bg.jpg) no-repeat center top; */
}
.widget-user .widget-user-username {
	margin-top: 0;
	margin-bottom: 5px;
	font-size: 25px;
	font-weight: 300;
	text-shadow: 0 1px 1px rgba(0,0,0,0.2)
}
.widget-user .widget-user-desc {
	margin-top: 0
}
.widget-user .widget-user-image {
	position: absolute;
	top: 100px;
	left: 50%;
	margin-left: -45px
}
.widget-user .widget-user-image>img {
	width: 90px;
	height: auto;
	border: 3px solid #fff
}
.widget-user .box-footer {
	padding-top: 58px
}
.widget-user-2 .widget-user-header {
	padding: 20px;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px
}
.widget-user-2 .widget-user-username {
	margin-top: 5px;
	margin-bottom: 5px;
	font-size: 25px;
	font-weight: 300
}
.widget-user-2 .widget-user-desc {
	margin-top: 0
}
.widget-user-2 .widget-user-username, .widget-user-2 .widget-user-desc {
	margin-left: 75px
}
.widget-user-2 .widget-user-image>img {
	width: 65px;
	height: auto;
	float: left;
	margin-right: 10px;
}
.full-wid {
	width: 100%;
}
.treeview-menu {
	display: none;
	list-style: none;
	padding: 0;
	margin: 0;
	padding-left: 5px;
	background-color: #f5f5f5;
}
.treeview-menu .treeview-menu {
	padding-left: 20px
}
.treeview-menu>li {
	margin: 0
}
.treeview-menu>li>a {
	padding: 8px 0px 8px 15px;
	display: block;
	font-size: 13px;
	color: #192844;
}
.treeview-menu>li>a:hover, .treeview-menu>li.active a {
	color: #FF5D19;
}
.treeview-menu>li>a>.fa, .treeview-menu>li>a>.glyphicon, .treeview-menu>li>a>.ion {
	width: 10px
}
.treeview-menu>li>a>.pull-right-container>.fa-angle-left, .treeview-menu>li>a>.pull-right-container>.fa-angle-down, .treeview-menu>li>a>.fa-angle-left, .treeview-menu>li>a>.fa-angle-down {
	width: auto
}
.mailbox-messages>.table {
	margin: 0
}
.mailbox-messages>.table td {
	padding: 14px 10px;
	font-size: 13px
}
.mailbox-messages>.table td a {
	color: #666f73;
}
.mailbox-messages>.table td a:hover {
	color: #4e5558;
}
.mailbox-controls {
	padding: 5px
}
.mailbox-controls.with-border {
	border-bottom: 1px solid #f4f4f4
}
.mailbox-read-info {
	border-bottom: 1px solid #f4f4f4;
	padding: 10px
}
.mailbox-read-info h3 {
	font-size: 20px;
	margin: 0
}
.mailbox-read-info h5 {
	margin: 0;
	padding: 5px 0 0 0
}
.mailbox-read-time {
	color: #999;
	font-size: 13px
}
.mailbox-read-message {
	padding: 10px
}
.mailbox-attachments li {
	float: left;
	width: 225px;
	border: 1px solid #eee;
	margin-bottom: 10px;
	margin-right: 10px
}
.mailbox-attachment-name {
	font-weight: bold;
	color: #666
}
.mailbox-attachment-icon, .mailbox-attachment-info, .mailbox-attachment-size {
	display: block
}
.mailbox-attachment-info {
	padding: 10px;
	background: #f4f4f4
}
.mailbox-attachment-size {
	color: #999;
	font-size: 12px
}
.mailbox-attachment-icon {
	text-align: center;
	font-size: 65px;
	color: #666;
	padding: 20px 10px
}
.mailbox-attachment-icon.has-img {
	padding: 0
}
.mailbox-attachment-icon.has-img>img {
	max-width: 100%;
	height: auto
}
.lockscreen {
	background: #d3d6dc
}
.lockscreen-logo {
	font-size: 35px;
	text-align: center;
	margin-bottom: 25px;
	font-weight: 300
}
.lockscreen-logo a {
	color: #444
}
.lockscreen-wrapper {
	max-width: 400px;
	margin: 0 auto;
	margin-top: 10%
}
.lockscreen .lockscreen-name {
	text-align: center;
	font-weight: 600
}
.lockscreen-item {
	border-radius: 4px;
	padding: 0;
	background: #fff;
	position: relative;
	margin: 10px auto 30px auto;
	width: 290px
}
.lockscreen-image {
	border-radius: 50%;
	position: absolute;
	left: -10px;
	top: -25px;
	background: #fff;
	padding: 5px;
	z-index: 10
}
.lockscreen-image>img {
	border-radius: 50%;
	width: 70px;
	height: 70px
}
.lockscreen-credentials {
	margin-left: 70px
}
.lockscreen-credentials .form-control {
	border: 0
}
.lockscreen-credentials .btn {
	background-color: #fff;
	border: 0;
	padding: 0 10px
}
.lockscreen-footer {
	margin-top: 10px
}
.login-logo, .register-logo {
	font-size: 35px;
	text-align: center;
	margin-bottom: 25px;
	font-weight: 300
}
.login-logo a, .register-logo a {
	color: #444
}
.login-page, .register-page {
	/* background: url(./img/body-bg.jpg) no-repeat center top;
	background-size: cover; */
}
.login-page.sty1, .register-page.sty1 {
	/* background: url(./img/body-bg1.jpg) no-repeat center top;
	background-size: cover; */
}
.login-box, .register-box {
	width: 400px;
	margin: 7% auto;
}
.login-box.sty1, .register-box.sty1 {
	right: 0px;
	position: absolute;
	height: 100%;
	margin: 0;
}
@media (max-width:768px) {
.login-box, .register-box {
	width: 90%;
	margin-top: 20px
}
}
.login-box-body, .register-box-body {
	background: #fff;
	padding: 20px;
	border-top: 0;
	color: #666;
	border-radius: 7px;
}
.login-box-body.sty1, .register-box-body.sty1 {
	border-radius: 0px;
	height: 100%;
	padding-top: 15%;
}
.login-box-body .form-control-feedback, .register-box-body .form-control-feedback {
	color: #777
}
.login-box-msg, .register-box-msg {
	margin: 0;
	text-align: center;
	padding: 0 20px 5px 20px
}
.social-auth-links {
	margin: 10px 0
}
.error-page {
	width: 600px;
	margin: 100px auto 0 auto
}
@media (max-width:991px) {
.error-page {
	width: 100%
}
}
.error-page>.headline {
	font-size: 150px;
	font-weight: 600
}
@media (max-width:991px) {
.error-page>.headline {
	float: none;
	text-align: center
}
}
.error-page>.error-content {
	margin-left: 190px;
	display: block
}
@media (max-width:991px) {
.error-page>.error-content {
	margin-left: 0
}
}
.error-page>.error-content>h3 {
	font-weight: 300;
	font-size: 25px
}
@media (max-width:991px) {
.error-page>.error-content>h3 {
	text-align: center
}
}
.invoice {
	position: relative;
	background: #fff;
	border: 1px solid #f4f4f4;
	padding: 0px;
	margin: 0px
}
.invoice-title {
	margin-top: 0
}
.profile-user-img {
	margin: 0 auto;
	width: 100px;
	padding: 3px;
	border: 3px solid #d2d6de
}
.profile-username {
	font-size: 21px;
	margin-top: 5px
}
.post {
	border-bottom: 1px solid #d2d6de;
	margin-bottom: 15px;
	padding-bottom: 15px;
	color: #666
}
.post:last-of-type {
	border-bottom: 0;
	margin-bottom: 0;
	padding-bottom: 0
}
.post .user-block {
	margin-bottom: 15px
}
.btn-social {
	position: relative;
	padding-left: 44px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis
}
.btn-social>:first-child {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 32px;
	line-height: 34px;
	font-size: 1.6em;
	text-align: center;
	border-right: 1px solid rgba(0,0,0,0.2)
}
.btn-social.btn-lg {
	padding-left: 61px
}
.btn-social.btn-lg>:first-child {
	line-height: 45px;
	width: 45px;
	font-size: 1.8em
}
.btn-social.btn-sm {
	padding-left: 38px
}
.btn-social.btn-sm>:first-child {
	line-height: 28px;
	width: 28px;
	font-size: 1.4em
}
.btn-social.btn-xs {
	padding-left: 30px
}
.btn-social.btn-xs>:first-child {
	line-height: 20px;
	width: 20px;
	font-size: 1.2em
}
.btn-social-icon {
	position: relative;
	padding-left: 44px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 34px;
	width: 34px;
	padding: 0;
	font-size: 12px !important;
}
.btn-social-icon>:first-child {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 32px;
	line-height: 34px;
	font-size: 1.6em;
	text-align: center;
	border-right: 1px solid rgba(0,0,0,0.2)
}
.btn-social-icon.btn-lg {
	padding-left: 61px
}
.btn-social-icon.btn-lg>:first-child {
	line-height: 45px;
	width: 45px;
	font-size: 1.8em
}
.btn-social-icon.btn-sm {
	padding-left: 38px
}
.btn-social-icon.btn-sm>:first-child {
	line-height: 28px;
	width: 28px;
	font-size: 1.4em
}
.btn-social-icon.btn-xs {
	padding-left: 30px
}
.btn-social-icon.btn-xs>:first-child {
	line-height: 20px;
	width: 20px;
	font-size: 1.2em
}
.btn-social-icon>:first-child {
	border: none;
	text-align: center;
	width: 100%
}
.btn-social-icon.btn-lg {
	height: 45px;
	width: 45px;
	padding-left: 0;
	padding-right: 0
}
.btn-social-icon.btn-sm {
	height: 30px;
	width: 30px;
	padding-left: 0;
	padding-right: 0
}
.btn-social-icon.btn-xs {
	height: 22px;
	width: 22px;
	padding-left: 0;
	padding-right: 0
}
.btn-adn {
	color: #fff;
	background-color: #d87a68;
	border-color: rgba(0,0,0,0.2)
}
.btn-adn:focus, .btn-adn.focus {
	color: #fff;
	background-color: #ce563f;
	border-color: rgba(0,0,0,0.2)
}
.btn-adn:hover {
	color: #fff;
	background-color: #ce563f;
	border-color: rgba(0,0,0,0.2)
}
.btn-adn:active, .btn-adn.active, .open>.dropdown-toggle.btn-adn {
	color: #fff;
	background-color: #ce563f;
	border-color: rgba(0,0,0,0.2)
}
.btn-adn:active, .btn-adn.active, .open>.dropdown-toggle.btn-adn {
	background-image: none
}
.btn-adn .badge {
	color: #d87a68;
	background-color: #fff
}
.btn-bitbucket {
	color: #fff !important;
	background-color: #205081;
	border-color: rgba(0,0,0,0.2)
}
.btn-bitbucket:focus, .btn-bitbucket.focus {
	color: #fff;
	background-color: #163758;
	border-color: rgba(0,0,0,0.2)
}
.btn-bitbucket:hover {
	color: #fff;
	background-color: #163758;
	border-color: rgba(0,0,0,0.2)
}
.btn-bitbucket:active, .btn-bitbucket.active, .open>.dropdown-toggle.btn-bitbucket {
	color: #fff;
	background-color: #163758;
	border-color: rgba(0,0,0,0.2)
}
.btn-bitbucket:active, .btn-bitbucket.active, .open>.dropdown-toggle.btn-bitbucket {
	background-image: none
}
.btn-bitbucket .badge {
	color: #205081;
	background-color: #fff
}
.btn-dropbox {
	color: #fff !important;
	background-color: #1087dd;
	border-color: rgba(0,0,0,0.2)
}
.btn-dropbox:focus, .btn-dropbox.focus {
	color: #fff;
	background-color: #0d6aad;
	border-color: rgba(0,0,0,0.2)
}
.btn-dropbox:hover {
	color: #fff;
	background-color: #0d6aad;
	border-color: rgba(0,0,0,0.2)
}
.btn-dropbox:active, .btn-dropbox.active, .open>.dropdown-toggle.btn-dropbox {
	color: #fff;
	background-color: #0d6aad;
	border-color: rgba(0,0,0,0.2)
}
.btn-dropbox:active, .btn-dropbox.active, .open>.dropdown-toggle.btn-dropbox {
	background-image: none
}
.btn-dropbox .badge {
	color: #1087dd;
	background-color: #fff
}
.btn-facebook {
	color: #fff !important;
	background-color: #3b5998;
	border-color: rgba(0,0,0,0.2)
}
.btn-facebook:focus, .btn-facebook.focus {
	color: #fff;
	background-color: #2d4373;
	border-color: rgba(0,0,0,0.2)
}
.btn-facebook:hover {
	color: #fff;
	background-color: #2d4373;
	border-color: rgba(0,0,0,0.2)
}
.btn-facebook:active, .btn-facebook.active, .open>.dropdown-toggle.btn-facebook {
	color: #fff;
	background-color: #2d4373;
	border-color: rgba(0,0,0,0.2)
}
.btn-facebook:active, .btn-facebook.active, .open>.dropdown-toggle.btn-facebook {
	background-image: none
}
.btn-facebook .badge {
	color: #3b5998;
	background-color: #fff
}
.btn-flickr {
	color: #fff !important;
	background-color: #ff0084;
	border-color: rgba(0,0,0,0.2)
}
.btn-flickr:focus, .btn-flickr.focus {
	color: #fff;
	background-color: #cc006a;
	border-color: rgba(0,0,0,0.2)
}
.btn-flickr:hover {
	color: #fff;
	background-color: #cc006a;
	border-color: rgba(0,0,0,0.2)
}
.btn-flickr:active, .btn-flickr.active, .open>.dropdown-toggle.btn-flickr {
	color: #fff;
	background-color: #cc006a;
	border-color: rgba(0,0,0,0.2)
}
.btn-flickr:active, .btn-flickr.active, .open>.dropdown-toggle.btn-flickr {
	background-image: none
}
.btn-flickr .badge {
	color: #ff0084;
	background-color: #fff
}
.btn-foursquare {
	color: #fff !important;
	background-color: #f94877;
	border-color: rgba(0,0,0,0.2)
}
.btn-foursquare:focus, .btn-foursquare.focus {
	color: #fff;
	background-color: #f71752;
	border-color: rgba(0,0,0,0.2)
}
.btn-foursquare:hover {
	color: #fff;
	background-color: #f71752;
	border-color: rgba(0,0,0,0.2)
}
.btn-foursquare:active, .btn-foursquare.active, .open>.dropdown-toggle.btn-foursquare {
	color: #fff;
	background-color: #f71752;
	border-color: rgba(0,0,0,0.2)
}
.btn-foursquare:active, .btn-foursquare.active, .open>.dropdown-toggle.btn-foursquare {
	background-image: none
}
.btn-foursquare .badge {
	color: #f94877;
	background-color: #fff
}
.btn-github {
	color: #fff !important;
	background-color: #444;
	border-color: rgba(0,0,0,0.2)
}
.btn-github:focus, .btn-github.focus {
	color: #fff;
	background-color: #2b2b2b;
	border-color: rgba(0,0,0,0.2)
}
.btn-github:hover {
	color: #fff;
	background-color: #2b2b2b;
	border-color: rgba(0,0,0,0.2)
}
.btn-github:active, .btn-github.active, .open>.dropdown-toggle.btn-github {
	color: #fff;
	background-color: #2b2b2b;
	border-color: rgba(0,0,0,0.2)
}
.btn-github:active, .btn-github.active, .open>.dropdown-toggle.btn-github {
	background-image: none
}
.btn-github .badge {
	color: #444;
	background-color: #fff
}
.btn-google {
	color: #fff !important;
	background-color: #dd4b39;
	border-color: rgba(0,0,0,0.2)
}
.btn-google:focus, .btn-google.focus {
	color: #fff;
	background-color: #c23321;
	border-color: rgba(0,0,0,0.2)
}
.btn-google:hover {
	color: #fff;
	background-color: #c23321;
	border-color: rgba(0,0,0,0.2)
}
.btn-google:active, .btn-google.active, .open>.dropdown-toggle.btn-google {
	color: #fff;
	background-color: #c23321;
	border-color: rgba(0,0,0,0.2)
}
.btn-google:active, .btn-google.active, .open>.dropdown-toggle.btn-google {
	background-image: none
}
.btn-google .badge {
	color: #dd4b39;
	background-color: #fff
}
.btn-instagram {
	color: #fff !important;
	background-color: #3f729b;
	border-color: rgba(0,0,0,0.2)
}
.btn-instagram:focus, .btn-instagram.focus {
	color: #fff;
	background-color: #305777;
	border-color: rgba(0,0,0,0.2)
}
.btn-instagram:hover {
	color: #fff;
	background-color: #305777;
	border-color: rgba(0,0,0,0.2)
}
.btn-instagram:active, .btn-instagram.active, .open>.dropdown-toggle.btn-instagram {
	color: #fff;
	background-color: #305777;
	border-color: rgba(0,0,0,0.2)
}
.btn-instagram:active, .btn-instagram.active, .open>.dropdown-toggle.btn-instagram {
	background-image: none
}
.btn-instagram .badge {
	color: #3f729b;
	background-color: #fff
}
.btn-linkedin {
	color: #fff !important;
	background-color: #007bb6;
	border-color: rgba(0,0,0,0.2)
}
.btn-linkedin:focus, .btn-linkedin.focus {
	color: #fff;
	background-color: #005983;
	border-color: rgba(0,0,0,0.2)
}
.btn-linkedin:hover {
	color: #fff;
	background-color: #005983;
	border-color: rgba(0,0,0,0.2)
}
.btn-linkedin:active, .btn-linkedin.active, .open>.dropdown-toggle.btn-linkedin {
	color: #fff;
	background-color: #005983;
	border-color: rgba(0,0,0,0.2)
}
.btn-linkedin:active, .btn-linkedin.active, .open>.dropdown-toggle.btn-linkedin {
	background-image: none
}
.btn-linkedin .badge {
	color: #007bb6;
	background-color: #fff
}
.btn-microsoft {
	color: #fff !important;
	background-color: #2672ec;
	border-color: rgba(0,0,0,0.2)
}
.btn-microsoft:focus, .btn-microsoft.focus {
	color: #fff;
	background-color: #125acd;
	border-color: rgba(0,0,0,0.2)
}
.btn-microsoft:hover {
	color: #fff;
	background-color: #125acd;
	border-color: rgba(0,0,0,0.2)
}
.btn-microsoft:active, .btn-microsoft.active, .open>.dropdown-toggle.btn-microsoft {
	color: #fff;
	background-color: #125acd;
	border-color: rgba(0,0,0,0.2)
}
.btn-microsoft:active, .btn-microsoft.active, .open>.dropdown-toggle.btn-microsoft {
	background-image: none
}
.btn-microsoft .badge {
	color: #2672ec;
	background-color: #fff
}
.btn-openid {
	color: #fff !important;
	background-color: #f7931e;
	border-color: rgba(0,0,0,0.2)
}
.btn-openid:focus, .btn-openid.focus {
	color: #fff;
	background-color: #da7908;
	border-color: rgba(0,0,0,0.2)
}
.btn-openid:hover {
	color: #fff;
	background-color: #da7908;
	border-color: rgba(0,0,0,0.2)
}
.btn-openid:active, .btn-openid.active, .open>.dropdown-toggle.btn-openid {
	color: #fff;
	background-color: #da7908;
	border-color: rgba(0,0,0,0.2)
}
.btn-openid:active, .btn-openid.active, .open>.dropdown-toggle.btn-openid {
	background-image: none
}
.btn-openid .badge {
	color: #f7931e;
	background-color: #fff
}
.btn-pinterest {
	color: #fff !important;
	background-color: #cb2027;
	border-color: rgba(0,0,0,0.2)
}
.btn-pinterest:focus, .btn-pinterest.focus {
	color: #fff;
	background-color: #9f191f;
	border-color: rgba(0,0,0,0.2)
}
.btn-pinterest:hover {
	color: #fff;
	background-color: #9f191f;
	border-color: rgba(0,0,0,0.2)
}
.btn-pinterest:active, .btn-pinterest.active, .open>.dropdown-toggle.btn-pinterest {
	color: #fff;
	background-color: #9f191f;
	border-color: rgba(0,0,0,0.2)
}
.btn-pinterest:active, .btn-pinterest.active, .open>.dropdown-toggle.btn-pinterest {
	background-image: none
}
.btn-pinterest .badge {
	color: #cb2027;
	background-color: #fff
}
.btn-reddit {
	color: #000;
	background-color: #eff7ff;
	border-color: rgba(0,0,0,0.2)
}
.btn-reddit:focus, .btn-reddit.focus {
	color: #000;
	background-color: #bcddff;
	border-color: rgba(0,0,0,0.2)
}
.btn-reddit:hover {
	color: #000;
	background-color: #bcddff;
	border-color: rgba(0,0,0,0.2)
}
.btn-reddit:active, .btn-reddit.active, .open>.dropdown-toggle.btn-reddit {
	color: #000;
	background-color: #bcddff;
	border-color: rgba(0,0,0,0.2)
}
.btn-reddit:active, .btn-reddit.active, .open>.dropdown-toggle.btn-reddit {
	background-image: none
}
.btn-reddit .badge {
	color: #eff7ff;
	background-color: #000
}
.btn-soundcloud {
	color: #fff !important;
	background-color: #f50;
	border-color: rgba(0,0,0,0.2)
}
.btn-soundcloud:focus, .btn-soundcloud.focus {
	color: #fff;
	background-color: #c40;
	border-color: rgba(0,0,0,0.2)
}
.btn-soundcloud:hover {
	color: #fff;
	background-color: #c40;
	border-color: rgba(0,0,0,0.2)
}
.btn-soundcloud:active, .btn-soundcloud.active, .open>.dropdown-toggle.btn-soundcloud {
	color: #fff;
	background-color: #c40;
	border-color: rgba(0,0,0,0.2)
}
.btn-soundcloud:active, .btn-soundcloud.active, .open>.dropdown-toggle.btn-soundcloud {
	background-image: none
}
.btn-soundcloud .badge {
	color: #f50;
	background-color: #fff
}
.btn-tumblr {
	color: #fff !important;
	background-color: #2c4762;
	border-color: rgba(0,0,0,0.2)
}
.btn-tumblr:focus, .btn-tumblr.focus {
	color: #fff;
	background-color: #1c2d3f;
	border-color: rgba(0,0,0,0.2)
}
.btn-tumblr:hover {
	color: #fff;
	background-color: #1c2d3f;
	border-color: rgba(0,0,0,0.2)
}
.btn-tumblr:active, .btn-tumblr.active, .open>.dropdown-toggle.btn-tumblr {
	color: #fff;
	background-color: #1c2d3f;
	border-color: rgba(0,0,0,0.2)
}
.btn-tumblr:active, .btn-tumblr.active, .open>.dropdown-toggle.btn-tumblr {
	background-image: none
}
.btn-tumblr .badge {
	color: #2c4762;
	background-color: #fff
}
.btn-twitter {
	color: #fff !important;
	background-color: #55acee;
	border-color: rgba(0,0,0,0.2)
}
.btn-twitter:focus, .btn-twitter.focus {
	color: #fff;
	background-color: #2795e9;
	border-color: rgba(0,0,0,0.2)
}
.btn-twitter:hover {
	color: #fff;
	background-color: #2795e9;
	border-color: rgba(0,0,0,0.2)
}
.btn-twitter:active, .btn-twitter.active, .open>.dropdown-toggle.btn-twitter {
	color: #fff;
	background-color: #2795e9;
	border-color: rgba(0,0,0,0.2)
}
.btn-twitter:active, .btn-twitter.active, .open>.dropdown-toggle.btn-twitter {
	background-image: none
}
.btn-twitter .badge {
	color: #55acee;
	background-color: #fff
}
.btn-vimeo {
	color: #fff !important;
	background-color: #1ab7ea;
	border-color: rgba(0,0,0,0.2)
}
.btn-vimeo:focus, .btn-vimeo.focus {
	color: #fff;
	background-color: #1295bf;
	border-color: rgba(0,0,0,0.2)
}
.btn-vimeo:hover {
	color: #fff;
	background-color: #1295bf;
	border-color: rgba(0,0,0,0.2)
}
.btn-vimeo:active, .btn-vimeo.active, .open>.dropdown-toggle.btn-vimeo {
	color: #fff;
	background-color: #1295bf;
	border-color: rgba(0,0,0,0.2)
}
.btn-vimeo:active, .btn-vimeo.active, .open>.dropdown-toggle.btn-vimeo {
	background-image: none
}
.btn-vimeo .badge {
	color: #1ab7ea;
	background-color: #fff
}
.btn-vk {
	color: #fff !important;
	background-color: #587ea3;
	border-color: rgba(0,0,0,0.2)
}
.btn-vk:focus, .btn-vk.focus {
	color: #fff;
	background-color: #466482;
	border-color: rgba(0,0,0,0.2)
}
.btn-vk:hover {
	color: #fff;
	background-color: #466482;
	border-color: rgba(0,0,0,0.2)
}
.btn-vk:active, .btn-vk.active, .open>.dropdown-toggle.btn-vk {
	color: #fff;
	background-color: #466482;
	border-color: rgba(0,0,0,0.2)
}
.btn-vk:active, .btn-vk.active, .open>.dropdown-toggle.btn-vk {
	background-image: none
}
.btn-vk .badge {
	color: #587ea3;
	background-color: #fff
}
.btn-yahoo {
	color: #fff !important;
	background-color: #720e9e;
	border-color: rgba(0,0,0,0.2)
}
.btn-yahoo:focus, .btn-yahoo.focus {
	color: #fff;
	background-color: #500a6f;
	border-color: rgba(0,0,0,0.2)
}
.btn-yahoo:hover {
	color: #fff;
	background-color: #500a6f;
	border-color: rgba(0,0,0,0.2)
}
.btn-yahoo:active, .btn-yahoo.active, .open>.dropdown-toggle.btn-yahoo {
	color: #fff;
	background-color: #500a6f;
	border-color: rgba(0,0,0,0.2)
}
.btn-yahoo:active, .btn-yahoo.active, .open>.dropdown-toggle.btn-yahoo {
	background-image: none
}
.btn-yahoo .badge {
	color: #720e9e;
	background-color: #fff
}
.fc-button {
	background: #f4f4f4;
	background-image: none;
	color: #444;
	border-color: #ddd;
	border-bottom-color: #ddd
}
.fc-button:hover, .fc-button:active, .fc-button.hover {
	background-color: #e9e9e9
}
.fc-header-title h2 {
	font-size: 15px;
	line-height: 1.6em;
	color: #666;
	margin-left: 10px
}
.fc-header-right {
	padding-right: 10px
}
.fc-header-left {
	padding-left: 10px
}
.fc-widget-header {
	background: #fafafa
}
.fc-grid {
	width: 100%;
	border: 0
}
.fc-widget-header:first-of-type, .fc-widget-content:first-of-type {
	border-left: 0;
	border-right: 0
}
.fc-widget-header:last-of-type, .fc-widget-content:last-of-type {
	border-right: 0
}
.fc-toolbar {
	padding: 10px;
	margin: 0
}
.fc-day-number {
	font-size: 20px;
	font-weight: 300;
	padding-right: 10px
}
.fc-color-picker {
	list-style: none;
	margin: 0;
	padding: 0
}
.fc-color-picker>li {
	float: left;
	font-size: 30px;
	margin-right: 5px;
	line-height: 30px
}
.fc-color-picker>li .fa {
	-webkit-transition: -webkit-transform linear .3s;
	-moz-transition: -moz-transform linear .3s;
	-o-transition: -o-transform linear .3s;
	transition: transform linear .3s
}
.fc-color-picker>li .fa:hover {
	-webkit-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	-o-transform: rotate(30deg);
	transform: rotate(30deg)
}
#add-new-event {
	-webkit-transition: all linear .3s;
	-o-transition: all linear .3s;
	transition: all linear .3s
}
.external-event {
	padding: 5px 10px;
	margin-bottom: 4px;
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
	text-shadow: 0 1px 1px rgba(0,0,0,0.1);
	border-radius: 3px;
	cursor: move
}
.external-event:hover {
	box-shadow: inset 0 0 90px rgba(0,0,0,0.2)
}
.select2-container--default.select2-container--focus, .select2-selection.select2-container--focus, .select2-container--default:focus, .select2-selection:focus, .select2-container--default:active, .select2-selection:active {
	outline: none
}
.select2-container--default .select2-selection--single, .select2-selection .select2-selection--single {
	border: 1px solid #d2d6de;
	border-radius: 0;
	padding: 6px 12px;
	height: 34px
}
.select2-container--default.select2-container--open {
	border-color: #3c8dbc
}
.select2-dropdown {
	border: 1px solid #d2d6de;
	border-radius: 0
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #3c8dbc;
	color: white
}
.select2-results__option {
	padding: 6px 12px;
	user-select: none;
	-webkit-user-select: none
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-left: 0;
	padding-right: 0;
	height: auto;
	margin-top: -4px
}
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
	padding-right: 6px;
	padding-left: 20px
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 28px;
	right: 3px
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	margin-top: 0
}
.select2-dropdown .select2-search__field, .select2-search--inline .select2-search__field {
	border: 1px solid #d2d6de
}
.select2-dropdown .select2-search__field:focus, .select2-search--inline .select2-search__field:focus {
	outline: none
}
.select2-container--default.select2-container--focus .select2-selection--multiple, .select2-container--default .select2-search--dropdown .select2-search__field {
	border-color: #3c8dbc !important
}
.select2-container--default .select2-results__option[aria-disabled=true] {
	color: #999
}
.select2-container--default .select2-results__option[aria-selected=true] {
	background-color: #ddd
}
.select2-container--default .select2-results__option[aria-selected=true], .select2-container--default .select2-results__option[aria-selected=true]:hover {
	color: #444
}
.select2-container--default .select2-selection--multiple {
	border: 1px solid #d2d6de;
	border-radius: 0
}
.select2-container--default .select2-selection--multiple:focus {
	border-color: #3c8dbc
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
	border-color: #d2d6de
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
	background-color: #3c8dbc;
	border-color: #367fa9;
	padding: 1px 10px;
	color: #fff
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	margin-right: 5px;
	color: rgba(255,255,255,0.7)
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
	color: #fff
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 10px
}
.box .datepicker-inline, .box .datepicker-inline .datepicker-days, .box .datepicker-inline>table, .box .datepicker-inline .datepicker-days>table {
	width: 100%
}
.box .datepicker-inline td:hover, .box .datepicker-inline .datepicker-days td:hover, .box .datepicker-inline>table td:hover, .box .datepicker-inline .datepicker-days>table td:hover {
	background-color: rgba(255,255,255,0.3)
}
.box .datepicker-inline td.day.old, .box .datepicker-inline .datepicker-days td.day.old, .box .datepicker-inline>table td.day.old, .box .datepicker-inline .datepicker-days>table td.day.old, .box .datepicker-inline td.day.new, .box .datepicker-inline .datepicker-days td.day.new, .box .datepicker-inline>table td.day.new, .box .datepicker-inline .datepicker-days>table td.day.new {
	color: #777
}
.pad {
	padding: 10px
}
iframe {
	border: 0;
}
.margin {
	margin: 10px
}
.margin-bottom {
	margin-bottom: 20px
}
.margin-bottom-none {
	margin-bottom: 0
}
.margin-r-5 {
	margin-right: 5px
}
.inline {
	display: inline
}
.description-block {
	display: block;
	margin: 10px 0;
	text-align: center
}
.description-block.margin-bottom {
	margin-bottom: 25px
}
.description-block>.description-header {
	margin: 0;
	padding: 0;
	font-weight: 600;
	font-size: 16px
}
.description-block>.description-text {
	text-transform: uppercase
}
.bg-red, .bg-yellow, .bg-aqua, .bg-blue, .bg-light-blue, .bg-green, .bg-navy, .bg-teal, .bg-olive, .bg-lime, .bg-orange, .bg-fuchsia, .bg-purple, .bg-maroon, .bg-black, .bg-red-active, .bg-yellow-active, .bg-aqua-active, .bg-blue-active, .bg-light-blue-active, .bg-green-active, .bg-navy-active, .bg-teal-active, .bg-olive-active, .bg-lime-active, .bg-orange-active, .bg-fuchsia-active, .bg-purple-active, .bg-maroon-active, .bg-black-active, .callout.callout-danger, .callout.callout-warning, .callout.callout-info, .callout.callout-success, .alert-success, .alert-danger, .alert-error, .alert-warning, .alert-info, .label-danger, .label-info, .label-warning, .label-primary, .label-success, .modal-primary .modal-body, .modal-primary .modal-header, .modal-primary .modal-footer, .modal-warning .modal-body, .modal-warning .modal-header, .modal-warning .modal-footer, .modal-info .modal-body, .modal-info .modal-header, .modal-info .modal-footer, .modal-success .modal-body, .modal-success .modal-header, .modal-success .modal-footer, .modal-danger .modal-body, .modal-danger .modal-header, .modal-danger .modal-footer {
	color: #fff !important
}
.bg-gray {
	color: #000;
	background-color: #d2d6de !important
}
.bg-gray-light {
	background-color: #f7f7f7
}
.bg-black {
	background-color: #111 !important
}
.bg-red, .callout.callout-danger, .alert-danger, .alert-error, .label-danger, .modal-danger .modal-body {
	background-color: #dd4b39 !important
}
.bg-yellow, .callout.callout-warning, .alert-warning, .label-warning, .modal-warning .modal-body {
	background-color: #f39c12 !important
}
.bg-aqua, .callout.callout-info, .alert-info, .label-info, .modal-info .modal-body {
	background-color: #04c1c4 !important
}
.bg-blue {
	background-color: #192844 !important
}
.bg-light-blue, .label-primary, .modal-primary .modal-body {
	background-color: #3c8dbc !important
}
.bg-green, .callout.callout-success, .alert-success, .label-success, .modal-success .modal-body {
	background-color: #00a65a !important
}
.alert-primary {
	background: #0077d3;
	color: #FFF;
}
.alert-secondary {
	background: #868e96;
	color: #FFF;
}
.alert-dark {
	background: #343a40;
	color: #FFF;
}
.bg-darkblue {
	background-color: #0077d3 !important
}
.bg-navy {
	background-color: #001f3f !important
}
.bg-teal {
	background-color: #39cccc !important
}
.bg-olive {
	background-color: #3d9970 !important
}
.bg-lime {
	background-color: #01ff70 !important
}
.bg-orange {
	background-color: #ff851b !important
}
.bg-fuchsia {
	background-color: #f012be !important
}
.bg-purple {
	background-color: #605ca8 !important
}
.bg-maroon {
	background-color: #d81b60 !important
}
.bg-gray-active {
	color: #000;
	background-color: #b5bbc8 !important
}
.bg-black-active {
	background-color: #000 !important
}
.bg-red-active, .modal-danger .modal-header, .modal-danger .modal-footer {
	background-color: #d33724 !important
}
.bg-yellow-active, .modal-warning .modal-header, .modal-warning .modal-footer {
	background-color: #db8b0b !important
}
.bg-aqua-active, .modal-info .modal-header, .modal-info .modal-footer {
	background-color: #008cd3 !important
}
.bg-blue-active {
	background-color: #005384 !important
}
.bg-light-blue-active, .modal-primary .modal-header, .modal-primary .modal-footer {
	background-color: #357ca5 !important
}
.bg-green-active, .modal-success .modal-header, .modal-success .modal-footer {
	background-color: #008d4c !important
}
.bg-navy-active {
	background-color: #001a35 !important
}
.bg-teal-active {
	background-color: #30bbbb !important
}
.bg-olive-active {
	background-color: #368763 !important
}
.bg-lime-active {
	background-color: #00e765 !important
}
.bg-orange-active {
	background-color: #ff7701 !important
}
.bg-fuchsia-active {
	background-color: #db0ead !important
}
.bg-purple-active {
	background-color: #555299 !important
}
.bg-maroon-active {
	background-color: #ca195a !important
}
[class^="bg-"].disabled {
	opacity: .65;
	filter: alpha(opacity=65)
}
.text-red {
	color: #dd4b39 !important
}
.text-yellow {
	color: #f39c12 !important
}
.text-aqua {
	color: #008cd3 !important
}
.text-blue {
	color: #0077d3 !important
}
.text-black {
	color: #111 !important
}
.text-light-blue {
	color: #3c8dbc !important
}
.text-green {
	color: #00a65a !important
}
.text-gray {
	color: #d2d6de !important
}
.text-navy {
	color: #001f3f !important
}
.text-teal {
	color: #39cccc !important
}
.text-olive {
	color: #3d9970 !important
}
.text-lime {
	color: #01ff70 !important
}
.text-orange {
	color: #ff851b !important
}
.text-fuchsia {
	color: #f012be !important
}
.text-purple {
	color: #605ca8 !important
}
.text-maroon {
	color: #d81b60 !important
}
.link-muted {
	color: #7a869d
}
.link-muted:hover, .link-muted:focus {
	color: #606c84
}
.link-black {
	color: #666
}
.link-black:hover, .link-black:focus {
	color: #999
}
.hide {
	display: none !important
}
.no-border {
	border: 0 !important
}
.no-padding {
	padding: 0 !important
}
.no-margin {
	margin: 0 !important
}
.no-shadow {
	box-shadow: none !important
}
.list-unstyled, .chart-legend, .contacts-list, .users-list, .mailbox-attachments {
	list-style: none;
	margin: 0;
	padding: 0
}
.list-group-unbordered>.list-group-item {
	border-left: 0;
	border-right: 0;
	border-radius: 0;
	padding-left: 0;
	padding-right: 0
}
.flat {
	border-radius: 0 !important
}
.text-bold, .text-bold.table td, .text-bold.table th {
	font-weight: 700
}
.text-sm {
	font-size: 12px
}
.jqstooltip {
	padding: 5px !important;
	width: auto !important;
	height: auto !important
}
.bg-teal-gradient {
	background: #39cccc !important;
	background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #39cccc), color-stop(1, #7adddd)) !important;
	background: -ms-linear-gradient(bottom, #39cccc, #7adddd) !important;
	background: -moz-linear-gradient(center bottom, #39cccc 0, #7adddd 100%) !important;
	background: -o-linear-gradient(#7adddd, #39cccc) !important;
filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#7adddd', endColorstr='#39cccc', GradientType=0) !important;
	color: #fff
}
.bg-light-blue-gradient {
	background: #3c8dbc !important;
	background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #3c8dbc), color-stop(1, #67a8ce)) !important;
	background: -ms-linear-gradient(bottom, #3c8dbc, #67a8ce) !important;
	background: -moz-linear-gradient(center bottom, #3c8dbc 0, #67a8ce 100%) !important;
	background: -o-linear-gradient(#67a8ce, #3c8dbc) !important;
filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#67a8ce', endColorstr='#3c8dbc', GradientType=0) !important;
	color: #fff
}
.bg-blue-gradient {
	background: #0073b7 !important;
	background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #0073b7), color-stop(1, #0089db)) !important;
	background: -ms-linear-gradient(bottom, #0073b7, #0089db) !important;
	background: -moz-linear-gradient(center bottom, #0073b7 0, #0089db 100%) !important;
	background: -o-linear-gradient(#0089db, #0073b7) !important;
filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#0089db', endColorstr='#0073b7', GradientType=0) !important;
	color: #fff
}
.bg-aqua-gradient {
	background: #008cd3 !important;
	background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #008cd3), color-stop(1, #14d1ff)) !important;
	background: -ms-linear-gradient(bottom, #008cd3, #14d1ff) !important;
	background: -moz-linear-gradient(center bottom, #008cd3 0, #14d1ff 100%) !important;
	background: -o-linear-gradient(#14d1ff, #008cd3) !important;
filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#14d1ff', endColorstr='#008cd3', GradientType=0) !important;
	color: #fff
}
.bg-yellow-gradient {
	background: #f39c12 !important;
	background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #f39c12), color-stop(1, #f7bc60)) !important;
	background: -ms-linear-gradient(bottom, #f39c12, #f7bc60) !important;
	background: -moz-linear-gradient(center bottom, #f39c12 0, #f7bc60 100%) !important;
	background: -o-linear-gradient(#f7bc60, #f39c12) !important;
filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7bc60', endColorstr='#f39c12', GradientType=0) !important;
	color: #fff
}
.bg-purple-gradient {
	background: #605ca8 !important;
	background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #605ca8), color-stop(1, #9491c4)) !important;
	background: -ms-linear-gradient(bottom, #605ca8, #9491c4) !important;
	background: -moz-linear-gradient(center bottom, #605ca8 0, #9491c4 100%) !important;
	background: -o-linear-gradient(#9491c4, #605ca8) !important;
filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#9491c4', endColorstr='#605ca8', GradientType=0) !important;
	color: #fff
}
.bg-green-gradient {
	background: #00a65a !important;
	background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #00a65a), color-stop(1, #00ca6d)) !important;
	background: -ms-linear-gradient(bottom, #00a65a, #00ca6d) !important;
	background: -moz-linear-gradient(center bottom, #00a65a 0, #00ca6d 100%) !important;
	background: -o-linear-gradient(#00ca6d, #00a65a) !important;
filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ca6d', endColorstr='#00a65a', GradientType=0) !important;
	color: #fff
}
.bg-red-gradient {
	background: #dd4b39 !important;
	background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #dd4b39), color-stop(1, #e47365)) !important;
	background: -ms-linear-gradient(bottom, #dd4b39, #e47365) !important;
	background: -moz-linear-gradient(center bottom, #dd4b39 0, #e47365 100%) !important;
	background: -o-linear-gradient(#e47365, #dd4b39) !important;
filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#e47365', endColorstr='#dd4b39', GradientType=0) !important;
	color: #fff
}
.bg-black-gradient {
	background: #111 !important;
	background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #111), color-stop(1, #2b2b2b)) !important;
	background: -ms-linear-gradient(bottom, #111, #2b2b2b) !important;
	background: -moz-linear-gradient(center bottom, #111 0, #2b2b2b 100%) !important;
	background: -o-linear-gradient(#2b2b2b, #111) !important;
filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#2b2b2b', endColorstr='#111111', GradientType=0) !important;
	color: #fff
}
.bg-maroon-gradient {
	background: #d81b60 !important;
	background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #d81b60), color-stop(1, #e73f7c)) !important;
	background: -ms-linear-gradient(bottom, #d81b60, #e73f7c) !important;
	background: -moz-linear-gradient(center bottom, #d81b60 0, #e73f7c 100%) !important;
	background: -o-linear-gradient(#e73f7c, #d81b60) !important;
filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#e73f7c', endColorstr='#d81b60', GradientType=0) !important;
	color: #fff
}
.description-block .description-icon {
	font-size: 16px
}
.bg-facebook {
	background-color: #3c5a9a;
}
.no-pad-top {
	padding-top: 0
}
.position-static {
	position: static !important
}
.list-header {
	font-size: 15px;
	padding: 10px 4px;
	font-weight: bold;
	color: #666
}
.list-seperator {
	height: 1px;
	background: #f4f4f4;
	margin: 15px 0 9px 0
}
.list-link>a {
	padding: 4px;
	color: #777
}
.list-link>a:hover {
	color: #222
}
.font-light {
	font-weight: 300
}
.user-block:before, .user-block:after {
	content: " ";
	display: table
}
.user-block:after {
	clear: both
}
.user-block img {
	width: 40px;
	height: 40px;
	float: left
}
.user-block .username, .user-block .description, .user-block .comment {
	display: block;
	margin-left: 50px
}
.user-block .username {
	font-size: 16px;
	font-weight: 600
}
.user-block .description {
	color: #999;
	font-size: 13px
}
.user-block.user-block-sm .username, .user-block.user-block-sm .description, .user-block.user-block-sm .comment {
	margin-left: 40px
}
.user-block.user-block-sm .username {
	font-size: 14px
}
.img-sm, .img-md, .img-lg, .box-comments .box-comment img, .user-block.user-block-sm img {
	float: left
}
.img-sm, .box-comments .box-comment img, .user-block.user-block-sm img {
	width: 30px !important;
	height: 30px !important
}
.img-sm+.img-push {
	margin-left: 40px
}
.img-md {
	width: 60px;
	height: 60px
}
.img-md+.img-push {
	margin-left: 70px
}
.img-lg {
	width: 100px;
	height: 100px
}
.img-lg+.img-push {
	margin-left: 110px
}
.img-bordered {
	border: 3px solid #d2d6de;
	padding: 3px
}
.img-bordered-sm {
	border: 2px solid #d2d6de;
	padding: 2px
}
.attachment-block {
	border: 1px solid #f4f4f4;
	padding: 5px;
	margin-bottom: 10px;
	background: #f7f7f7
}
.attachment-block .attachment-img {
	max-width: 100px;
	max-height: 100px;
	height: auto;
	float: left
}
.attachment-block .attachment-pushed {
	margin-left: 110px
}
.attachment-block .attachment-heading {
	margin: 0
}
.attachment-block .attachment-text {
	color: #555
}
.connectedSortable {
	min-height: 100px
}
.ui-helper-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px
}
.sort-highlight {
	background: #f4f4f4;
	border: 1px dashed #ddd;
	margin-bottom: 10px
}
.full-opacity-hover {
	opacity: .65;
	filter: alpha(opacity=65)
}
.full-opacity-hover:hover {
	opacity: 1;
	filter: alpha(opacity=100)
}
.chart {
	position: relative;
	overflow: hidden;
	width: 100%;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}
.chart svg, .chart canvas {
	width: 100% !important
}
@media print {
.no-print, .main-sidebar, .left-side, .main-header, .content-header {
	display: none !important
}
.content-wrapper, .right-side, .main-footer {
	margin-left: 0 !important;
	min-height: 0 !important;
	-webkit-transform: translate(0, 0) !important;
	-ms-transform: translate(0, 0) !important;
	-o-transform: translate(0, 0) !important;
	transform: translate(0, 0) !important
}
.fixed .content-wrapper, .fixed .right-side {
	padding-top: 0 !important
}
.invoice {
	width: 100%;
	border: 0;
	margin: 0;
	padding: 0
}
.invoice-col {
	float: left;
	width: 33.3333333%
}
.table-responsive {
	overflow: auto
}
.table-responsive>.table tr th, .table-responsive>.table tr td {
	white-space: normal !important
}
}
/*----- Morris Chart -----*/
.morris-hover {
	position: absolute;
	z-index: 1000
}
.morris-hover.morris-default-style {
	border-radius: 5px;
	padding: 5px 10px;
	color: #fff;
	background: rgb(0, 0, 0, 0.8);
	font-family: sans-serif;
	font-size: 13px;
	text-align: center
}
.morris-hover.morris-default-style .morris-hover-row-label {
	font-weight: bold;
	margin: 0.25em 0
}
.morris-hover.morris-default-style .morris-hover-point {
	white-space: nowrap;
	margin: 0.1em 0;
	color: #FFF !important;
}
.peity {
	width: 100%;
}
/* Resets */
.m-b-0 {
	margin-bottom: 0px !important;
}
.m-b-1 {
	margin-bottom: 10px !important;
}
.m-b-15 {
	margin-bottom: 15px !important;
}
.m-b-2 {
	margin-bottom: 20px !important;
}
.m-b-3 {
	margin-bottom: 30px !important;
}
.m-b-4 {
	margin-bottom: 40px !important;
}
.m-b-5 {
	margin-bottom: 50px !important;
}
.m-b-6 {
	margin-bottom: 60px !important;
}
.m-b-7 {
	margin-bottom: 70px !important;
}
.m-b-8 {
	margin-bottom: 80px !important;
}
.m-t-1 {
	margin-top: 10px !important;
}
.m-t-15 {
	margin-top: 15px !important;
}
.m-t-2 {
	margin-top: 20px !important;
}
.m-t-3 {
	margin-top: 30px !important;
}
.m-t-4 {
	margin-top: 40px !important;
}
.m-t-5 {
	margin-top: 50px !important;
}
.m-t-6 {
	margin-top: 60px !important;
}
.m-t-7 {
	margin-top: 70px !important;
}
.m-t-8 {
	margin-top: 80px !important;
}
.m-r-1 {
	margin-right: 10px;
}
.m-r-2 {
	margin-right: 20px;
}
.m-r-3 {
	margin-right: 30px;
}
.m-r-4 {
	margin-right: 40px;
}
.m-r-5 {
	margin-right: 50px;
}
.m-r-6 {
	margin-right: 60px;
}
.f-20 {
	font-size: 20px;
}
.f-14 {
	font-size: 13px;
}
.f-25 {
	font-size: 25px;
}
.f-30 {
	font-size: 30px;
}
.f-40 {
	font-size: 40px;
}
.f-50 {
	font-size: 50px;
}
.f-60 {
	font-size: 60px;
}
.f-70 {
	font-size: 70px;
}
.overhidden {
	overflow: hidden;
}
.img-w-30 {
	width: 30px;
}
.pad-10 {
	padding: 10px;
}
.pad-20 {
	padding: 20px 15px;
}
.m-top-40 {
	margin-top: -40px !important;
}
.m-bot-10 {
	margin-bottom: -10px !important;
}

/*----------------------------- RESPONSIVE STYLES ---------------------------------- */

@media only screen and (min-width: 1000px) and (max-width: 1169px) {
}
 @media only screen and (min-width: 768px) and (max-width: 999px) {
}
 @media only screen and (min-width: 640px) and (max-width: 767px) {
.hidden-xs {
	display: none !important;
}
.main-header .navbar .navbar-nav > li > a {
	padding: 23px 18px 22px 18px;
}
.main-header .navbar .navbar-nav > li.p-ph-res > a {
	padding: 22px 18px 21px 18px;
}
}
@media only screen and (min-width: 480px) and (max-width: 639px) {
.hidden-xs {
	display: none !important;
}
.main-header .navbar .navbar-nav > li > a {
	padding: 23px 18px 22px 18px;
}
.main-header .navbar .navbar-nav > li.p-ph-res > a {
	padding: 22px 18px 21px 18px;
}
}
@media only screen and (max-width: 479px) {
.hidden-xs {
	display: none !important;
}
.main-header .navbar .navbar-nav > li > a {
	padding: 23px 18px 22px 18px;
}
.main-header .navbar .navbar-nav > li.p-ph-res > a {
	padding: 22px 18px 21px 18px;
}
.content {
    padding: 20px;
}
}

/* Custom Code by Albert Jakobi */
.login-username {
	margin-bottom: 10px;
}

.login-password {
	margin-bottom: 10px;
}

.login-button {
	margin: 0px auto;
	display: block;
}

.btn-primary:hover {
	background-color: #3e64ac !important;
}

.btn-primary:focus {
	background-color: #3e64ac !important;
}

.sidebar-bottom > p {
	text-align: center;
}

.full-width {
	width: 100%;
}

.radio-box {
	border: 1px solid #a0a0a0;
	border-radius: 5px;
}

.sidebar-mini.sidebar-collapse .sidebar-bottom {
	display: none;
}

.sm-padding {
	padding: 5px !important;
}

.addDomain-modal {
	width: 500px;
}

.sr-only {
	position:absolute;
	width:1px;
	height:1px;
	padding:0;
	margin:-1px;
	overflow:hidden;
	clip:rect(0,0,0,0);
	white-space:nowrap;
	border-width:0
   }
.not-sr-only {
	position:static;
	width:auto;
	height:auto;
	padding:0;
	margin:0;
	overflow:visible;
	clip:auto;
	white-space:normal
   }
.focus\:sr-only:focus {
	position:absolute;
	width:1px;
	height:1px;
	padding:0;
	margin:-1px;
	overflow:hidden;
	clip:rect(0,0,0,0);
	white-space:nowrap;
	border-width:0
   }
.focus\:not-sr-only:focus {
	position:static;
	width:auto;
	height:auto;
	padding:0;
	margin:0;
	overflow:visible;
	clip:auto;
	white-space:normal
   }
.appearance-none {
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none
   }
.bg-fixed {
	background-attachment:fixed
   }
.bg-local {
	background-attachment:local
   }
.bg-scroll {
	background-attachment:scroll
   }
.bg-transparent {
	background-color:transparent
   }
.bg-black {
	background-color:#000
   }
.bg-white {
	background-color:#fff
   }
.bg-gray-100 {
	background-color:#f7fafc
   }
.bg-gray-200 {
	background-color:#edf2f7
   }
.bg-gray-300 {
	background-color:#e2e8f0
   }
.bg-gray-400 {
	background-color:#cbd5e0
   }
.bg-gray-500 {
	background-color:#a0aec0
   }
.bg-gray-600 {
	background-color:#718096
   }
   .bg-gray-700 {
	background-color:#4a5568
   }
   .bg-gray-800 {
	background-color:#2d3748
   }
   .bg-gray-900 {
	background-color:#1a202c
   }
   .bg-red-100 {
	background-color:#fff5f5
   }
   .bg-red-200 {
	background-color:#fed7d7
   }
   .bg-red-300 {
	background-color:#feb2b2
   }
   .bg-red-400 {
	background-color:#fc8181
   }
   .bg-red-500 {
	background-color:#f56565
   }
   .bg-red-600 {
	background-color:#e53e3e
   }
   .bg-red-700 {
	background-color:#c53030
   }
   .bg-red-800 {
	background-color:#9b2c2c
   }
   .bg-red-900 {
	background-color:#742a2a
   }
   .bg-orange-100 {
	background-color:#fffaf0
   }
   .bg-orange-200 {
	background-color:#feebc8
   }
   .bg-orange-300 {
	background-color:#fbd38d
   }
   .bg-orange-400 {
	background-color:#f6ad55
   }
   .bg-orange-500 {
	background-color:#ed8936
   }
   .bg-orange-600 {
	background-color:#dd6b20
   }
   .bg-orange-700 {
	background-color:#c05621
   }
   .bg-orange-800 {
	background-color:#9c4221
   }
   .bg-orange-900 {
	background-color:#7b341e
   }
   .bg-yellow-100 {
	background-color:ivory
   }
   .bg-yellow-200 {
	background-color:#fefcbf
   }
   .bg-yellow-300 {
	background-color:#faf089
   }
   .bg-yellow-400 {
	background-color:#f6e05e
   }
   .bg-yellow-500 {
	background-color:#ecc94b
   }
   .bg-yellow-600 {
	background-color:#d69e2e
   }
   .bg-yellow-700 {
	background-color:#b7791f
   }
   .bg-yellow-800 {
	background-color:#975a16
   }
   .bg-yellow-900 {
	background-color:#744210
   }
   .bg-green-100 {
	background-color:#f0fff4
   }
   .bg-green-200 {
	background-color:#c6f6d5
   }
   .bg-green-300 {
	background-color:#9ae6b4
   }
   .bg-green-400 {
	background-color:#68d391
   }
   .bg-green-500 {
	background-color:#48bb78
   }
   .bg-green-600 {
	background-color:#38a169
   }
   .bg-green-700 {
	background-color:#2f855a
   }
   .bg-green-800 {
	background-color:#276749
   }
   .bg-green-900 {
	background-color:#22543d
   }
   .bg-teal-100 {
	background-color:#e6fffa
   }
   .bg-teal-200 {
	background-color:#b2f5ea
   }
   .bg-teal-300 {
	background-color:#81e6d9
   }
   .bg-teal-400 {
	background-color:#4fd1c5
   }
   .bg-teal-500 {
	background-color:#38b2ac
   }
   .bg-teal-600 {
	background-color:#319795
   }
   .bg-teal-700 {
	background-color:#2c7a7b
   }
   .bg-teal-800 {
	background-color:#285e61
   }
   .bg-teal-900 {
	background-color:#234e52
   }
   .bg-blue-100 {
	background-color:#ebf8ff
   }
   .bg-blue-200 {
	background-color:#bee3f8
   }
   .bg-blue-300 {
	background-color:#90cdf4
   }
   .bg-blue-400 {
	background-color:#63b3ed
   }
   .bg-blue-500 {
	background-color:#4299e1
   }
   .bg-blue-600 {
	background-color:#3182ce
   }
   .bg-blue-700 {
	background-color:#2b6cb0
   }
   .bg-blue-800 {
	background-color:#2c5282
   }
   .bg-blue-900 {
	background-color:#2a4365
   }
   .bg-indigo-100 {
	background-color:#ebf4ff
   }
   .bg-indigo-200 {
	background-color:#c3dafe
   }
   .bg-indigo-300 {
	background-color:#a3bffa
   }
   .bg-indigo-400 {
	background-color:#7f9cf5
   }
   .bg-indigo-500 {
	background-color:#667eea
   }
   .bg-indigo-600 {
	background-color:#5a67d8
   }
   .bg-indigo-700 {
	background-color:#4c51bf
   }
   .bg-indigo-800 {
	background-color:#434190
   }
   .bg-indigo-900 {
	background-color:#3c366b
   }
   .bg-purple-100 {
	background-color:#faf5ff
   }
   .bg-purple-200 {
	background-color:#e9d8fd
   }
   .bg-purple-300 {
	background-color:#d6bcfa
   }
   .bg-purple-400 {
	background-color:#b794f4
   }
   .bg-purple-500 {
	background-color:#9f7aea
   }
   .bg-purple-600 {
	background-color:#805ad5
   }
   .bg-purple-700 {
	background-color:#6b46c1
   }
   .bg-purple-800 {
	background-color:#553c9a
   }
   .bg-purple-900 {
	background-color:#44337a
   }
   .bg-pink-100 {
	background-color:#fff5f7
   }
   .bg-pink-200 {
	background-color:#fed7e2
   }
   .bg-pink-300 {
	background-color:#fbb6ce
   }
   .bg-pink-400 {
	background-color:#f687b3
   }
   .bg-pink-500 {
	background-color:#ed64a6
   }
   .bg-pink-600 {
	background-color:#d53f8c
   }
   .bg-pink-700 {
	background-color:#b83280
   }
   .bg-pink-800 {
	background-color:#97266d
   }
   .bg-pink-900 {
	background-color:#702459
   }
   .hover\:bg-transparent:hover {
	background-color:transparent
   }
   .hover\:bg-black:hover {
	background-color:#000
   }
   .hover\:bg-white:hover {
	background-color:#fff
   }
   .hover\:bg-gray-100:hover {
	background-color:#f7fafc
   }
   .hover\:bg-gray-200:hover {
	background-color:#edf2f7
   }
   .hover\:bg-gray-300:hover {
	background-color:#e2e8f0
   }
   .hover\:bg-gray-400:hover {
	background-color:#cbd5e0
   }
   .hover\:bg-gray-500:hover {
	background-color:#a0aec0
   }
   .hover\:bg-gray-600:hover {
	background-color:#718096
   }
   .hover\:bg-gray-700:hover {
	background-color:#4a5568
   }
   .hover\:bg-gray-800:hover {
	background-color:#2d3748
   }
   .hover\:bg-gray-900:hover {
	background-color:#1a202c
   }
   .hover\:bg-red-100:hover {
	background-color:#fff5f5
   }
   .hover\:bg-red-200:hover {
	background-color:#fed7d7
   }
   .hover\:bg-red-300:hover {
	background-color:#feb2b2
   }
   .hover\:bg-red-400:hover {
	background-color:#fc8181
   }
   .hover\:bg-red-500:hover {
	background-color:#f56565
   }
   .hover\:bg-red-600:hover {
	background-color:#e53e3e
   }
   .hover\:bg-red-700:hover {
	background-color:#c53030
   }
   .hover\:bg-red-800:hover {
	background-color:#9b2c2c
   }
   .hover\:bg-red-900:hover {
	background-color:#742a2a
   }
   .hover\:bg-orange-100:hover {
	background-color:#fffaf0
   }
   .hover\:bg-orange-200:hover {
	background-color:#feebc8
   }
   .hover\:bg-orange-300:hover {
	background-color:#fbd38d
   }
   .hover\:bg-orange-400:hover {
	background-color:#f6ad55
   }
   .hover\:bg-orange-500:hover {
	background-color:#ed8936
   }
   .hover\:bg-orange-600:hover {
	background-color:#dd6b20
   }
   .hover\:bg-orange-700:hover {
	background-color:#c05621
   }
   .hover\:bg-orange-800:hover {
	background-color:#9c4221
   }
   .hover\:bg-orange-900:hover {
	background-color:#7b341e
   }
   .hover\:bg-yellow-100:hover {
	background-color:ivory
   }
   .hover\:bg-yellow-200:hover {
	background-color:#fefcbf
   }
   .hover\:bg-yellow-300:hover {
	background-color:#faf089
   }
   .hover\:bg-yellow-400:hover {
	background-color:#f6e05e
   }
   .hover\:bg-yellow-500:hover {
	background-color:#ecc94b
   }
   .hover\:bg-yellow-600:hover {
	background-color:#d69e2e
   }
   .hover\:bg-yellow-700:hover {
	background-color:#b7791f
   }
   .hover\:bg-yellow-800:hover {
	background-color:#975a16
   }
   .hover\:bg-yellow-900:hover {
	background-color:#744210
   }
   .hover\:bg-green-100:hover {
	background-color:#f0fff4
   }
   .hover\:bg-green-200:hover {
	background-color:#c6f6d5
   }
   .hover\:bg-green-300:hover {
	background-color:#9ae6b4
   }
   .hover\:bg-green-400:hover {
	background-color:#68d391
   }
   .hover\:bg-green-500:hover {
	background-color:#48bb78
   }
   .hover\:bg-green-600:hover {
	background-color:#38a169
   }
   .hover\:bg-green-700:hover {
	background-color:#2f855a
   }
   .hover\:bg-green-800:hover {
	background-color:#276749
   }
   .hover\:bg-green-900:hover {
	background-color:#22543d
   }
   .hover\:bg-teal-100:hover {
	background-color:#e6fffa
   }
   .hover\:bg-teal-200:hover {
	background-color:#b2f5ea
   }
   .hover\:bg-teal-300:hover {
	background-color:#81e6d9
   }
   .hover\:bg-teal-400:hover {
	background-color:#4fd1c5
   }
   .hover\:bg-teal-500:hover {
	background-color:#38b2ac
   }
   .hover\:bg-teal-600:hover {
	background-color:#319795
   }
   .hover\:bg-teal-700:hover {
	background-color:#2c7a7b
   }
   .hover\:bg-teal-800:hover {
	background-color:#285e61
   }
   .hover\:bg-teal-900:hover {
	background-color:#234e52
   }
   .hover\:bg-blue-100:hover {
	background-color:#ebf8ff
   }
   .hover\:bg-blue-200:hover {
	background-color:#bee3f8
   }
   .hover\:bg-blue-300:hover {
	background-color:#90cdf4
   }
   .hover\:bg-blue-400:hover {
	background-color:#63b3ed
   }
   .hover\:bg-blue-500:hover {
	background-color:#4299e1
   }
   .hover\:bg-blue-600:hover {
	background-color:#3182ce
   }
   .hover\:bg-blue-700:hover {
	background-color:#2b6cb0
   }
   .hover\:bg-blue-800:hover {
	background-color:#2c5282
   }
   .hover\:bg-blue-900:hover {
	background-color:#2a4365
   }
   .hover\:bg-indigo-100:hover {
	background-color:#ebf4ff
   }
   .hover\:bg-indigo-200:hover {
	background-color:#c3dafe
   }
   .hover\:bg-indigo-300:hover {
	background-color:#a3bffa
   }
   .hover\:bg-indigo-400:hover {
	background-color:#7f9cf5
   }
   .hover\:bg-indigo-500:hover {
	background-color:#667eea
   }
   .hover\:bg-indigo-600:hover {
	background-color:#5a67d8
   }
   .hover\:bg-indigo-700:hover {
	background-color:#4c51bf
   }
   .hover\:bg-indigo-800:hover {
	background-color:#434190
   }
   .hover\:bg-indigo-900:hover {
	background-color:#3c366b
   }
   .hover\:bg-purple-100:hover {
	background-color:#faf5ff
   }
   .hover\:bg-purple-200:hover {
	background-color:#e9d8fd
   }
   .hover\:bg-purple-300:hover {
	background-color:#d6bcfa
   }
   .hover\:bg-purple-400:hover {
	background-color:#b794f4
   }
   .hover\:bg-purple-500:hover {
	background-color:#9f7aea
   }
   .hover\:bg-purple-600:hover {
	background-color:#805ad5
   }
   .hover\:bg-purple-700:hover {
	background-color:#6b46c1
   }
   .hover\:bg-purple-800:hover {
	background-color:#553c9a
   }
   .hover\:bg-purple-900:hover {
	background-color:#44337a
   }
   .hover\:bg-pink-100:hover {
	background-color:#fff5f7
   }
   .hover\:bg-pink-200:hover {
	background-color:#fed7e2
   }
   .hover\:bg-pink-300:hover {
	background-color:#fbb6ce
   }
   .hover\:bg-pink-400:hover {
	background-color:#f687b3
   }
   .hover\:bg-pink-500:hover {
	background-color:#ed64a6
   }
   .hover\:bg-pink-600:hover {
	background-color:#d53f8c
   }
   .hover\:bg-pink-700:hover {
	background-color:#b83280
   }
   .hover\:bg-pink-800:hover {
	background-color:#97266d
   }
   .hover\:bg-pink-900:hover {
	background-color:#702459
   }
   .focus\:bg-transparent:focus {
	background-color:transparent
   }
   .focus\:bg-black:focus {
	background-color:#000
   }
   .focus\:bg-white:focus {
	background-color:#fff
   }
   .focus\:bg-gray-100:focus {
	background-color:#f7fafc
   }
   .focus\:bg-gray-200:focus {
	background-color:#edf2f7
   }
   .focus\:bg-gray-300:focus {
	background-color:#e2e8f0
   }
   .focus\:bg-gray-400:focus {
	background-color:#cbd5e0
   }
   .focus\:bg-gray-500:focus {
	background-color:#a0aec0
   }
   .focus\:bg-gray-600:focus {
	background-color:#718096
   }
   .focus\:bg-gray-700:focus {
	background-color:#4a5568
   }
   .focus\:bg-gray-800:focus {
	background-color:#2d3748
   }
   .focus\:bg-gray-900:focus {
	background-color:#1a202c
   }
   .focus\:bg-red-100:focus {
	background-color:#fff5f5
   }
   .focus\:bg-red-200:focus {
	background-color:#fed7d7
   }
   .focus\:bg-red-300:focus {
	background-color:#feb2b2
   }
   .focus\:bg-red-400:focus {
	background-color:#fc8181
   }
   .focus\:bg-red-500:focus {
	background-color:#f56565
   }
   .focus\:bg-red-600:focus {
	background-color:#e53e3e
   }
   .focus\:bg-red-700:focus {
	background-color:#c53030
   }
   .focus\:bg-red-800:focus {
	background-color:#9b2c2c
   }
   .focus\:bg-red-900:focus {
	background-color:#742a2a
   }
   .focus\:bg-orange-100:focus {
	background-color:#fffaf0
   }
   .focus\:bg-orange-200:focus {
	background-color:#feebc8
   }
   .focus\:bg-orange-300:focus {
	background-color:#fbd38d
   }
   .focus\:bg-orange-400:focus {
	background-color:#f6ad55
   }
   .focus\:bg-orange-500:focus {
	background-color:#ed8936
   }
   .focus\:bg-orange-600:focus {
	background-color:#dd6b20
   }
   .focus\:bg-orange-700:focus {
	background-color:#c05621
   }
   .focus\:bg-orange-800:focus {
	background-color:#9c4221
   }
   .focus\:bg-orange-900:focus {
	background-color:#7b341e
   }
   .focus\:bg-yellow-100:focus {
	background-color:ivory
   }
   .focus\:bg-yellow-200:focus {
	background-color:#fefcbf
   }
   .focus\:bg-yellow-300:focus {
	background-color:#faf089
   }
   .focus\:bg-yellow-400:focus {
	background-color:#f6e05e
   }
   .focus\:bg-yellow-500:focus {
	background-color:#ecc94b
   }
   .focus\:bg-yellow-600:focus {
	background-color:#d69e2e
   }
   .focus\:bg-yellow-700:focus {
	background-color:#b7791f
   }
   .focus\:bg-yellow-800:focus {
	background-color:#975a16
   }
   .focus\:bg-yellow-900:focus {
	background-color:#744210
   }
   .focus\:bg-green-100:focus {
	background-color:#f0fff4
   }
   .focus\:bg-green-200:focus {
	background-color:#c6f6d5
   }
   .focus\:bg-green-300:focus {
	background-color:#9ae6b4
   }
   .focus\:bg-green-400:focus {
	background-color:#68d391
   }
   .focus\:bg-green-500:focus {
	background-color:#48bb78
   }
   .focus\:bg-green-600:focus {
	background-color:#38a169
   }
   .focus\:bg-green-700:focus {
	background-color:#2f855a
   }
   .focus\:bg-green-800:focus {
	background-color:#276749
   }
   .focus\:bg-green-900:focus {
	background-color:#22543d
   }
   .focus\:bg-teal-100:focus {
	background-color:#e6fffa
   }
   .focus\:bg-teal-200:focus {
	background-color:#b2f5ea
   }
   .focus\:bg-teal-300:focus {
	background-color:#81e6d9
   }
   .focus\:bg-teal-400:focus {
	background-color:#4fd1c5
   }
   .focus\:bg-teal-500:focus {
	background-color:#38b2ac
   }
   .focus\:bg-teal-600:focus {
	background-color:#319795
   }
   .focus\:bg-teal-700:focus {
	background-color:#2c7a7b
   }
   .focus\:bg-teal-800:focus {
	background-color:#285e61
   }
   .focus\:bg-teal-900:focus {
	background-color:#234e52
   }
   .focus\:bg-blue-100:focus {
	background-color:#ebf8ff
   }
   .focus\:bg-blue-200:focus {
	background-color:#bee3f8
   }
   .focus\:bg-blue-300:focus {
	background-color:#90cdf4
   }
   .focus\:bg-blue-400:focus {
	background-color:#63b3ed
   }
   .focus\:bg-blue-500:focus {
	background-color:#4299e1
   }
   .focus\:bg-blue-600:focus {
	background-color:#3182ce
   }
   .focus\:bg-blue-700:focus {
	background-color:#2b6cb0
   }
   .focus\:bg-blue-800:focus {
	background-color:#2c5282
   }
   .focus\:bg-blue-900:focus {
	background-color:#2a4365
   }
   .focus\:bg-indigo-100:focus {
	background-color:#ebf4ff
   }
   .focus\:bg-indigo-200:focus {
	background-color:#c3dafe
   }
   .focus\:bg-indigo-300:focus {
	background-color:#a3bffa
   }
   .focus\:bg-indigo-400:focus {
	background-color:#7f9cf5
   }
   .focus\:bg-indigo-500:focus {
	background-color:#667eea
   }
   .focus\:bg-indigo-600:focus {
	background-color:#5a67d8
   }
   .focus\:bg-indigo-700:focus {
	background-color:#4c51bf
   }
   .focus\:bg-indigo-800:focus {
	background-color:#434190
   }
   .focus\:bg-indigo-900:focus {
	background-color:#3c366b
   }
   .focus\:bg-purple-100:focus {
	background-color:#faf5ff
   }
   .focus\:bg-purple-200:focus {
	background-color:#e9d8fd
   }
   .focus\:bg-purple-300:focus {
	background-color:#d6bcfa
   }
   .focus\:bg-purple-400:focus {
	background-color:#b794f4
   }
   .focus\:bg-purple-500:focus {
	background-color:#9f7aea
   }
   .focus\:bg-purple-600:focus {
	background-color:#805ad5
   }
   .focus\:bg-purple-700:focus {
	background-color:#6b46c1
   }
   .focus\:bg-purple-800:focus {
	background-color:#553c9a
   }
   .focus\:bg-purple-900:focus {
	background-color:#44337a
   }
   .focus\:bg-pink-100:focus {
	background-color:#fff5f7
   }
   .focus\:bg-pink-200:focus {
	background-color:#fed7e2
   }
   .focus\:bg-pink-300:focus {
	background-color:#fbb6ce
   }
   .focus\:bg-pink-400:focus {
	background-color:#f687b3
   }
   .focus\:bg-pink-500:focus {
	background-color:#ed64a6
   }
   .focus\:bg-pink-600:focus {
	background-color:#d53f8c
   }
   .focus\:bg-pink-700:focus {
	background-color:#b83280
   }
   .focus\:bg-pink-800:focus {
	background-color:#97266d
   }
   .focus\:bg-pink-900:focus {
	background-color:#702459
   }
   .bg-bottom {
	background-position:bottom
   }
   .bg-center {
	background-position:50%
   }
   .bg-left {
	background-position:0
   }
   .bg-left-bottom {
	background-position:0 100%
   }
   .bg-left-top {
	background-position:0 0
   }
   .bg-right {
	background-position:100%
   }
   .bg-right-bottom {
	background-position:100% 100%
   }
   .bg-right-top {
	background-position:100% 0
   }
   .bg-top {
	background-position:top
   }
   .bg-repeat {
	background-repeat:repeat
   }
   .bg-no-repeat {
	background-repeat:no-repeat
   }
   .bg-repeat-x {
	background-repeat:repeat-x
   }
   .bg-repeat-y {
	background-repeat:repeat-y
   }
   .bg-repeat-round {
	background-repeat:round
   }
   .bg-repeat-space {
	background-repeat:space
   }
   .bg-auto {
	background-size:auto
   }
   .bg-cover {
	background-size:cover
   }
   .bg-contain {
	background-size:contain
   }
   .border-collapse {
	border-collapse:collapse
   }
   .border-separate {
	border-collapse:separate
   }
   .border-transparent {
	border-color:transparent
   }
   .border-black {
	border-color:#000
   }
   .border-white {
	border-color:#fff
   }
   .border-gray-100 {
	border-color:#f7fafc
   }
   .border-gray-200 {
	border-color:#edf2f7
   }
   .border-gray-300 {
	border-color:#e2e8f0
   }
   .border-gray-400 {
	border-color:#cbd5e0
   }
   .border-gray-500 {
	border-color:#a0aec0
   }
   .border-gray-600 {
	border-color:#718096
   }
   .border-gray-700 {
	border-color:#4a5568
   }
   .border-gray-800 {
	border-color:#2d3748
   }
   .border-gray-900 {
	border-color:#1a202c
   }
   .border-red-100 {
	border-color:#fff5f5
   }
   .border-red-200 {
	border-color:#fed7d7
   }
   .border-red-300 {
	border-color:#feb2b2
   }
   .border-red-400 {
	border-color:#fc8181
   }
   .border-red-500 {
	border-color:#f56565
   }
   .border-red-600 {
	border-color:#e53e3e
   }
   .border-red-700 {
	border-color:#c53030
   }
   .border-red-800 {
	border-color:#9b2c2c
   }
   .border-red-900 {
	border-color:#742a2a
   }
   .border-orange-100 {
	border-color:#fffaf0
   }
   .border-orange-200 {
	border-color:#feebc8
   }
   .border-orange-300 {
	border-color:#fbd38d
   }
   .border-orange-400 {
	border-color:#f6ad55
   }
   .border-orange-500 {
	border-color:#ed8936
   }
   .border-orange-600 {
	border-color:#dd6b20
   }
   .border-orange-700 {
	border-color:#c05621
   }
   .border-orange-800 {
	border-color:#9c4221
   }
   .border-orange-900 {
	border-color:#7b341e
   }
   .border-yellow-100 {
	border-color:ivory
   }
   .border-yellow-200 {
	border-color:#fefcbf
   }
   .border-yellow-300 {
	border-color:#faf089
   }
   .border-yellow-400 {
	border-color:#f6e05e
   }
   .border-yellow-500 {
	border-color:#ecc94b
   }
   .border-yellow-600 {
	border-color:#d69e2e
   }
   .border-yellow-700 {
	border-color:#b7791f
   }
   .border-yellow-800 {
	border-color:#975a16
   }
   .border-yellow-900 {
	border-color:#744210
   }
   .border-green-100 {
	border-color:#f0fff4
   }
   .border-green-200 {
	border-color:#c6f6d5
   }
   .border-green-300 {
	border-color:#9ae6b4
   }
   .border-green-400 {
	border-color:#68d391
   }
   .border-green-500 {
	border-color:#48bb78
   }
   .border-green-600 {
	border-color:#38a169
   }
   .border-green-700 {
	border-color:#2f855a
   }
   .border-green-800 {
	border-color:#276749
   }
   .border-green-900 {
	border-color:#22543d
   }
   .border-teal-100 {
	border-color:#e6fffa
   }
   .border-teal-200 {
	border-color:#b2f5ea
   }
   .border-teal-300 {
	border-color:#81e6d9
   }
   .border-teal-400 {
	border-color:#4fd1c5
   }
   .border-teal-500 {
	border-color:#38b2ac
   }
   .border-teal-600 {
	border-color:#319795
   }
   .border-teal-700 {
	border-color:#2c7a7b
   }
   .border-teal-800 {
	border-color:#285e61
   }
   .border-teal-900 {
	border-color:#234e52
   }
   .border-blue-100 {
	border-color:#ebf8ff
   }
   .border-blue-200 {
	border-color:#bee3f8
   }
   .border-blue-300 {
	border-color:#90cdf4
   }
   .border-blue-400 {
	border-color:#63b3ed
   }
   .border-blue-500 {
	border-color:#4299e1
   }
   .border-blue-600 {
	border-color:#3182ce
   }
   .border-blue-700 {
	border-color:#2b6cb0
   }
   .border-blue-800 {
	border-color:#2c5282
   }
   .border-blue-900 {
	border-color:#2a4365
   }
   .border-indigo-100 {
	border-color:#ebf4ff
   }
   .border-indigo-200 {
	border-color:#c3dafe
   }
   .border-indigo-300 {
	border-color:#a3bffa
   }
   .border-indigo-400 {
	border-color:#7f9cf5
   }
   .border-indigo-500 {
	border-color:#667eea
   }
   .border-indigo-600 {
	border-color:#5a67d8
   }
   .border-indigo-700 {
	border-color:#4c51bf
   }
   .border-indigo-800 {
	border-color:#434190
   }
   .border-indigo-900 {
	border-color:#3c366b
   }
   .border-purple-100 {
	border-color:#faf5ff
   }
   .border-purple-200 {
	border-color:#e9d8fd
   }
   .border-purple-300 {
	border-color:#d6bcfa
   }
   .border-purple-400 {
	border-color:#b794f4
   }
   .border-purple-500 {
	border-color:#9f7aea
   }
   .border-purple-600 {
	border-color:#805ad5
   }
   .border-purple-700 {
	border-color:#6b46c1
   }
   .border-purple-800 {
	border-color:#553c9a
   }
   .border-purple-900 {
	border-color:#44337a
   }
   .border-pink-100 {
	border-color:#fff5f7
   }
   .border-pink-200 {
	border-color:#fed7e2
   }
   .border-pink-300 {
	border-color:#fbb6ce
   }
   .border-pink-400 {
	border-color:#f687b3
   }
   .border-pink-500 {
	border-color:#ed64a6
   }
   .border-pink-600 {
	border-color:#d53f8c
   }
   .border-pink-700 {
	border-color:#b83280
   }
   .border-pink-800 {
	border-color:#97266d
   }
   .border-pink-900 {
	border-color:#702459
   }
   .hover\:border-transparent:hover {
	border-color:transparent
   }
   .hover\:border-black:hover {
	border-color:#000
   }
   .hover\:border-white:hover {
	border-color:#fff
   }
   .hover\:border-gray-100:hover {
	border-color:#f7fafc
   }
   .hover\:border-gray-200:hover {
	border-color:#edf2f7
   }
   .hover\:border-gray-300:hover {
	border-color:#e2e8f0
   }
   .hover\:border-gray-400:hover {
	border-color:#cbd5e0
   }
   .hover\:border-gray-500:hover {
	border-color:#a0aec0
   }
   .hover\:border-gray-600:hover {
	border-color:#718096
   }
   .hover\:border-gray-700:hover {
	border-color:#4a5568
   }
   .hover\:border-gray-800:hover {
	border-color:#2d3748
   }
   .hover\:border-gray-900:hover {
	border-color:#1a202c
   }
   .hover\:border-red-100:hover {
	border-color:#fff5f5
   }
   .hover\:border-red-200:hover {
	border-color:#fed7d7
   }
   .hover\:border-red-300:hover {
	border-color:#feb2b2
   }
   .hover\:border-red-400:hover {
	border-color:#fc8181
   }
   .hover\:border-red-500:hover {
	border-color:#f56565
   }
   .hover\:border-red-600:hover {
	border-color:#e53e3e
   }
   .hover\:border-red-700:hover {
	border-color:#c53030
   }
   .hover\:border-red-800:hover {
	border-color:#9b2c2c
   }
   .hover\:border-red-900:hover {
	border-color:#742a2a
   }
   .hover\:border-orange-100:hover {
	border-color:#fffaf0
   }
   .hover\:border-orange-200:hover {
	border-color:#feebc8
   }
   .hover\:border-orange-300:hover {
	border-color:#fbd38d
   }
   .hover\:border-orange-400:hover {
	border-color:#f6ad55
   }
   .hover\:border-orange-500:hover {
	border-color:#ed8936
   }
   .hover\:border-orange-600:hover {
	border-color:#dd6b20
   }
   .hover\:border-orange-700:hover {
	border-color:#c05621
   }
   .hover\:border-orange-800:hover {
	border-color:#9c4221
   }
   .hover\:border-orange-900:hover {
	border-color:#7b341e
   }
   .hover\:border-yellow-100:hover {
	border-color:ivory
   }
   .hover\:border-yellow-200:hover {
	border-color:#fefcbf
   }
   .hover\:border-yellow-300:hover {
	border-color:#faf089
   }
   .hover\:border-yellow-400:hover {
	border-color:#f6e05e
   }
   .hover\:border-yellow-500:hover {
	border-color:#ecc94b
   }
   .hover\:border-yellow-600:hover {
	border-color:#d69e2e
   }
   .hover\:border-yellow-700:hover {
	border-color:#b7791f
   }
   .hover\:border-yellow-800:hover {
	border-color:#975a16
   }
   .hover\:border-yellow-900:hover {
	border-color:#744210
   }
   .hover\:border-green-100:hover {
	border-color:#f0fff4
   }
   .hover\:border-green-200:hover {
	border-color:#c6f6d5
   }
   .hover\:border-green-300:hover {
	border-color:#9ae6b4
   }
   .hover\:border-green-400:hover {
	border-color:#68d391
   }
   .hover\:border-green-500:hover {
	border-color:#48bb78
   }
   .hover\:border-green-600:hover {
	border-color:#38a169
   }
   .hover\:border-green-700:hover {
	border-color:#2f855a
   }
   .hover\:border-green-800:hover {
	border-color:#276749
   }
   .hover\:border-green-900:hover {
	border-color:#22543d
   }
   .hover\:border-teal-100:hover {
	border-color:#e6fffa
   }
   .hover\:border-teal-200:hover {
	border-color:#b2f5ea
   }
   .hover\:border-teal-300:hover {
	border-color:#81e6d9
   }
   .hover\:border-teal-400:hover {
	border-color:#4fd1c5
   }
   .hover\:border-teal-500:hover {
	border-color:#38b2ac
   }
   .hover\:border-teal-600:hover {
	border-color:#319795
   }
   .hover\:border-teal-700:hover {
	border-color:#2c7a7b
   }
   .hover\:border-teal-800:hover {
	border-color:#285e61
   }
   .hover\:border-teal-900:hover {
	border-color:#234e52
   }
   .hover\:border-blue-100:hover {
	border-color:#ebf8ff
   }
   .hover\:border-blue-200:hover {
	border-color:#bee3f8
   }
   .hover\:border-blue-300:hover {
	border-color:#90cdf4
   }
   .hover\:border-blue-400:hover {
	border-color:#63b3ed
   }
   .hover\:border-blue-500:hover {
	border-color:#4299e1
   }
   .hover\:border-blue-600:hover {
	border-color:#3182ce
   }
   .hover\:border-blue-700:hover {
	border-color:#2b6cb0
   }
   .hover\:border-blue-800:hover {
	border-color:#2c5282
   }
   .hover\:border-blue-900:hover {
	border-color:#2a4365
   }
   .hover\:border-indigo-100:hover {
	border-color:#ebf4ff
   }
   .hover\:border-indigo-200:hover {
	border-color:#c3dafe
   }
   .hover\:border-indigo-300:hover {
	border-color:#a3bffa
   }
   .hover\:border-indigo-400:hover {
	border-color:#7f9cf5
   }
   .hover\:border-indigo-500:hover {
	border-color:#667eea
   }
   .hover\:border-indigo-600:hover {
	border-color:#5a67d8
   }
   .hover\:border-indigo-700:hover {
	border-color:#4c51bf
   }
   .hover\:border-indigo-800:hover {
	border-color:#434190
   }
   .hover\:border-indigo-900:hover {
	border-color:#3c366b
   }
   .hover\:border-purple-100:hover {
	border-color:#faf5ff
   }
   .hover\:border-purple-200:hover {
	border-color:#e9d8fd
   }
   .hover\:border-purple-300:hover {
	border-color:#d6bcfa
   }
   .hover\:border-purple-400:hover {
	border-color:#b794f4
   }
   .hover\:border-purple-500:hover {
	border-color:#9f7aea
   }
   .hover\:border-purple-600:hover {
	border-color:#805ad5
   }
   .hover\:border-purple-700:hover {
	border-color:#6b46c1
   }
   .hover\:border-purple-800:hover {
	border-color:#553c9a
   }
   .hover\:border-purple-900:hover {
	border-color:#44337a
   }
   .hover\:border-pink-100:hover {
	border-color:#fff5f7
   }
   .hover\:border-pink-200:hover {
	border-color:#fed7e2
   }
   .hover\:border-pink-300:hover {
	border-color:#fbb6ce
   }
   .hover\:border-pink-400:hover {
	border-color:#f687b3
   }
   .hover\:border-pink-500:hover {
	border-color:#ed64a6
   }
   .hover\:border-pink-600:hover {
	border-color:#d53f8c
   }
   .hover\:border-pink-700:hover {
	border-color:#b83280
   }
   .hover\:border-pink-800:hover {
	border-color:#97266d
   }
   .hover\:border-pink-900:hover {
	border-color:#702459
   }
   .focus\:border-transparent:focus {
	border-color:transparent
   }
   .focus\:border-black:focus {
	border-color:#000
   }
   .focus\:border-white:focus {
	border-color:#fff
   }
   .focus\:border-gray-100:focus {
	border-color:#f7fafc
   }
   .focus\:border-gray-200:focus {
	border-color:#edf2f7
   }
   .focus\:border-gray-300:focus {
	border-color:#e2e8f0
   }
   .focus\:border-gray-400:focus {
	border-color:#cbd5e0
   }
   .focus\:border-gray-500:focus {
	border-color:#a0aec0
   }
   .focus\:border-gray-600:focus {
	border-color:#718096
   }
   .focus\:border-gray-700:focus {
	border-color:#4a5568
   }
   .focus\:border-gray-800:focus {
	border-color:#2d3748
   }
   .focus\:border-gray-900:focus {
	border-color:#1a202c
   }
   .focus\:border-red-100:focus {
	border-color:#fff5f5
   }
   .focus\:border-red-200:focus {
	border-color:#fed7d7
   }
   .focus\:border-red-300:focus {
	border-color:#feb2b2
   }
   .focus\:border-red-400:focus {
	border-color:#fc8181
   }
   .focus\:border-red-500:focus {
	border-color:#f56565
   }
   .focus\:border-red-600:focus {
	border-color:#e53e3e
   }
   .focus\:border-red-700:focus {
	border-color:#c53030
   }
   .focus\:border-red-800:focus {
	border-color:#9b2c2c
   }
   .focus\:border-red-900:focus {
	border-color:#742a2a
   }
   .focus\:border-orange-100:focus {
	border-color:#fffaf0
   }
   .focus\:border-orange-200:focus {
	border-color:#feebc8
   }
   .focus\:border-orange-300:focus {
	border-color:#fbd38d
   }
   .focus\:border-orange-400:focus {
	border-color:#f6ad55
   }
   .focus\:border-orange-500:focus {
	border-color:#ed8936
   }
   .focus\:border-orange-600:focus {
	border-color:#dd6b20
   }
   .focus\:border-orange-700:focus {
	border-color:#c05621
   }
   .focus\:border-orange-800:focus {
	border-color:#9c4221
   }
   .focus\:border-orange-900:focus {
	border-color:#7b341e
   }
   .focus\:border-yellow-100:focus {
	border-color:ivory
   }
   .focus\:border-yellow-200:focus {
	border-color:#fefcbf
   }
   .focus\:border-yellow-300:focus {
	border-color:#faf089
   }
   .focus\:border-yellow-400:focus {
	border-color:#f6e05e
   }
   .focus\:border-yellow-500:focus {
	border-color:#ecc94b
   }
   .focus\:border-yellow-600:focus {
	border-color:#d69e2e
   }
   .focus\:border-yellow-700:focus {
	border-color:#b7791f
   }
   .focus\:border-yellow-800:focus {
	border-color:#975a16
   }
   .focus\:border-yellow-900:focus {
	border-color:#744210
   }
   .focus\:border-green-100:focus {
	border-color:#f0fff4
   }
   .focus\:border-green-200:focus {
	border-color:#c6f6d5
   }
   .focus\:border-green-300:focus {
	border-color:#9ae6b4
   }
   .focus\:border-green-400:focus {
	border-color:#68d391
   }
   .focus\:border-green-500:focus {
	border-color:#48bb78
   }
   .focus\:border-green-600:focus {
	border-color:#38a169
   }
   .focus\:border-green-700:focus {
	border-color:#2f855a
   }
   .focus\:border-green-800:focus {
	border-color:#276749
   }
   .focus\:border-green-900:focus {
	border-color:#22543d
   }
   .focus\:border-teal-100:focus {
	border-color:#e6fffa
   }
   .focus\:border-teal-200:focus {
	border-color:#b2f5ea
   }
   .focus\:border-teal-300:focus {
	border-color:#81e6d9
   }
   .focus\:border-teal-400:focus {
	border-color:#4fd1c5
   }
   .focus\:border-teal-500:focus {
	border-color:#38b2ac
   }
   .focus\:border-teal-600:focus {
	border-color:#319795
   }
   .focus\:border-teal-700:focus {
	border-color:#2c7a7b
   }
   .focus\:border-teal-800:focus {
	border-color:#285e61
   }
   .focus\:border-teal-900:focus {
	border-color:#234e52
   }
   .focus\:border-blue-100:focus {
	border-color:#ebf8ff
   }
   .focus\:border-blue-200:focus {
	border-color:#bee3f8
   }
   .focus\:border-blue-300:focus {
	border-color:#90cdf4
   }
   .focus\:border-blue-400:focus {
	border-color:#63b3ed
   }
   .focus\:border-blue-500:focus {
	border-color:#4299e1
   }
   .focus\:border-blue-600:focus {
	border-color:#3182ce
   }
   .focus\:border-blue-700:focus {
	border-color:#2b6cb0
   }
   .focus\:border-blue-800:focus {
	border-color:#2c5282
   }
   .focus\:border-blue-900:focus {
	border-color:#2a4365
   }
   .focus\:border-indigo-100:focus {
	border-color:#ebf4ff
   }
   .focus\:border-indigo-200:focus {
	border-color:#c3dafe
   }
   .focus\:border-indigo-300:focus {
	border-color:#a3bffa
   }
   .focus\:border-indigo-400:focus {
	border-color:#7f9cf5
   }
   .focus\:border-indigo-500:focus {
	border-color:#667eea
   }
   .focus\:border-indigo-600:focus {
	border-color:#5a67d8
   }
   .focus\:border-indigo-700:focus {
	border-color:#4c51bf
   }
   .focus\:border-indigo-800:focus {
	border-color:#434190
   }
   .focus\:border-indigo-900:focus {
	border-color:#3c366b
   }
   .focus\:border-purple-100:focus {
	border-color:#faf5ff
   }
   .focus\:border-purple-200:focus {
	border-color:#e9d8fd
   }
   .focus\:border-purple-300:focus {
	border-color:#d6bcfa
   }
   .focus\:border-purple-400:focus {
	border-color:#b794f4
   }
   .focus\:border-purple-500:focus {
	border-color:#9f7aea
   }
   .focus\:border-purple-600:focus {
	border-color:#805ad5
   }
   .focus\:border-purple-700:focus {
	border-color:#6b46c1
   }
   .focus\:border-purple-800:focus {
	border-color:#553c9a
   }
   .focus\:border-purple-900:focus {
	border-color:#44337a
   }
   .focus\:border-pink-100:focus {
	border-color:#fff5f7
   }
   .focus\:border-pink-200:focus {
	border-color:#fed7e2
   }
   .focus\:border-pink-300:focus {
	border-color:#fbb6ce
   }
   .focus\:border-pink-400:focus {
	border-color:#f687b3
   }
   .focus\:border-pink-500:focus {
	border-color:#ed64a6
   }
   .focus\:border-pink-600:focus {
	border-color:#d53f8c
   }
   .focus\:border-pink-700:focus {
	border-color:#b83280
   }
   .focus\:border-pink-800:focus {
	border-color:#97266d
   }
   .focus\:border-pink-900:focus {
	border-color:#702459
   }

.addDomain-modal {
   text-align: center;
}

#reseller-table td {
   white-space: nowrap;
}

.badge {
	display: inline-block;
	min-width: 10px;
	padding: 3px 7px;
	font-size: 12px;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	background-color: #777;
	border-radius: 10px;
}

.checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"], .radio input[type="radio"], .radio-inline input[type="radio"] {
	position: absolute;
	margin-top: 4px\9;
	margin-left: -20px;
}

input[type="checkbox"], input[type="radio"] {
	margin: 4px 0 0;
	margin-left: 0px;
	margin-top: 1px\9;
	line-height: normal;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
}

#handle-create-extended .form-control.handle-datechoose, #handle-edit-extended .form-control.handle-datechoose {
	padding: 0 0;
	border: 0px !important;
}

#handle-create-extended .form-control.handle-datechoose select, #handle-edit-extended .form-control.handle-datechoose select {
    width: 30% !important;
    margin-right: 2%;
    float: left;
}

.domain-deletedate select {
	width: 30% !important;
    margin-right: 2%;
	float: left;
}

.domain-deletedate {
	border: none !important;
}

select {
    background-color: #ffffff;
    border: 1px solid #aaa !important;
    border-radius: 4px !important;
    -moz-user-select: none;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
}

button, input, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button, select {
    text-transform: none;
}

button, input, optgroup, select, textarea {
    margin: 0;
        margin-right: 0px;
    font: inherit;
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
    color: inherit;
}

.modal-dialog {
	text-align: left;
}

.modal-body .checkbox {
	position: relative;
	display: block;
	margin-top: 10px;
	margin-bottom: 20px;
}

.modal-body .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"], .radio input[type="radio"], .radio-inline input[type="radio"] {
    position: absolute;
    margin-top: 4px\9;
	margin-left: -20px;
	margin: 4px 0 0;
        margin-left: 0px;
    margin-top: 1px\9;
	line-height: normal;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

.modal-body .checkbox label, .radio label {
    font-weight: 400;
    cursor: pointer;
}

.modal-body .checkbox input[type=checkbox],.checkbox-inline input[type=checkbox],.radio input[type=radio],.radio-inline input[type=radio] {
	position:absolute;
	margin-top:4px\9;
	margin-left:-20px;
	margin-top: -30px;
	height: 20px;
}

.modal-body .checkbox+.checkbox,.radio+.radio {
	margin-top:-5px
}

.modal-body .checkbox-inline,.radio-inline {
	position:relative;
	display:inline-block;
	padding-left:20px;
	margin-bottom:0;
	font-weight:400;
	vertical-align:middle;
	cursor:pointer
}

.modal-body .checkbox-inline+.checkbox-inline,.radio-inline+.radio-inline {
	margin-top:0;
	margin-left:10px
}

fieldset[disabled] input[type=checkbox],fieldset[disabled] input[type=radio],input[type=checkbox].disabled,input[type=checkbox][disabled],input[type=radio].disabled,input[type=radio][disabled] {
	cursor:not-allowed
}

.modal-body .checkbox-inline.disabled,.radio-inline.disabled,fieldset[disabled] .checkbox-inline,fieldset[disabled] .radio-inline {
	cursor:not-allowed
}

.checkbox.disabled label,.radio.disabled label,fieldset[disabled] .checkbox label,fieldset[disabled] .radio label {
	cursor:not-allowed
}

.nowrap {
	white-space: nowrap;
}

a.disabled {
	pointer-events: none;
	cursor: default;
}

.table-datatables {
	max-width:1616px !important;
}

.panel-control::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    left: -1em;
    position: relative;
}

.panel-control {
	background-color: #192844;
    height: 100%;
    position: relative;
    float: right;
    padding: 0 15px;
}

.panel > .panel-heading::after, .panel.panel-colorful > .panel-heading::after {
    content: '';
    display: block;
    position: absolute;
    height: 0;
    left: 20px;
    right: 20px;
}

.panel .panel-title, .panel .panel-heading {
    background-color: #192844;
    color: #ffffff;
}
.panel .panel-heading, .panel > :first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.panel-heading {
    position: relative;
    height: 50px;
	padding: 0;
	position: relative;
	height: 50px;
	padding: 0;
}
.panel-heading {
    padding: 10px 15px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel-heading > span {
	font-size: 16px;
	font-weight: bold;

}

.panel-heading > span > a {
	color: white;
	font-weight: bold;
}

.panel-control .nav-tabs > li {
    margin-top: 0px;
    margin-right: 5px;
    height: 100%;
}

.nav-tabs > li {
    float: left;
    margin-bottom: -1px;
}
.nav > li {
    position: relative;
    display: block;
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.panel-control .nav-tabs {
    display: inline-block;
    height: 40px;
    vertical-align: bottom;
    border: 0;
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.panel-control .nav-tabs > .active > a {
	z-index: 1;
}

.panel-control .nav-tabs > li a {
	z-index: 1;
	background-color: #ffffff;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    background-color: #ffffff;
}

.panel-control .nav-tabs > li > a {
    border-radius: 0;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    margin-right: 0;
    height: 100%;
    line-height: 40px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
	padding: 0 15px;
	color: #555;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    color: #555;
    background-color: #fff;
    border: 1px solid #ddd;
        border-bottom-color: rgb(221, 221, 221);
    border-bottom-color: transparent;
}

.panel-control .nav-tabs > li > a:not(.active) {
    background-color: rgba(255, 255, 255, 0.8);
    color: #666;
}

.panel-control .nav-tabs > li > a:not(.active) {
    border-bottom: 0 !important;
}

.table {
	border: 1px solid #ccc;
}

#whoisQuicksearchModal {
	position: absolute;
	width: 500px;
	height: 400px;
	background-color: white;
	padding: 30px;
	margin-right: auto;
	margin-left: auto;
	left: 0;
	right: 0;
	top: 60px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.float-right {
	float: right;
}

.float-left {
	float: left;
}

.swal-text {
	text-align: center !important;
}