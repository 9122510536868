body {
    background-color: lightgray;
}

.btn-webgo {
    color: #fff;
    background-color: #192844;
    border-color: #192844;
}

.btn-webgo:hover {
    color: #fff;
    background-color: #ff773c;
    border-color: #ff773c;
}

label.required:after {
    content: "* ";
    color: red;
}