.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 3em;
  padding: .3em;
  background: #242424;
  color: #fff;
  font-family: Oxygen,Helvetica,Arial,sans-serif;
  font-weight: 700;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear; }
  .chartist-tooltip:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -5px;
    border: 5px solid transparent;
    border-top-color: #242424; }
  .chartist-tooltip.tooltip-show {
    opacity: 1; }

.ct-area, .ct-line {
  pointer-events: none; }

/*# sourceMappingURL=chartist-plugin-tooltip.css.map */
